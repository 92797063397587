<template>
  <div class="sub-cont">
    <div class="h2-tit">
      <h2>근무 조건</h2>
    </div>

    <h3 class="h3-tit">자격조건</h3>
    <fieldset>
      <div class="input_form">
        <div class="t-head">성별<i class="required"></i></div>
        <div class="t-doc">
          <ul class="check_list">
            <li
              v-for="(genderItem, idx) in GENDER_OPTION"
              :key="genderItem.name"
            >
              <input
                v-model="genderCode"
                type="radio"
                class="checkbox"
                :ref="'genderCode_' + idx"
                :id="genderItem.name"
                :value="genderItem.value"
              />
              <label :for="genderItem.name">{{ genderItem.name }}</label>
            </li>
            <!-- 
            <li>
              <input type="checkbox" class="checkbox" id="c1" checked />
              <label for="c1">성별무관</label>
            </li>
            <li>
              <input type="checkbox" class="checkbox" id="c2" />
              <label for="c2">남성</label>
            </li>
            <li>
              <input type="checkbox" class="checkbox" id="c3" />
              <label for="c3">여성</label>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">나이<i class="required"></i></div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="(ageItem, idx) in AGE_OPTION" :key="ageItem.name">
              <input
                v-model="ageCode"
                type="radio"
                class="checkbox"
                :ref="'ageItem_' + idx"
                :id="ageItem.name"
                :value="ageItem.value"
              />
              <label :for="ageItem.name">{{ ageItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="ageCode === AGE_OPTION[1].value" class="input_form">
        <div class="t-head"></div>
        <div class="t-doc">
          <div class="flex age">
            <select v-model="ageMin" name="" id="" class="select mini">
              <option v-for="age in AGE_RANGE" :key="`ai_${age}`" :value="age">
                {{ age }}
              </option>
            </select>
            <span v-if="ageCode === AGE_OPTION[1].value">-</span>
            <select
              v-if="ageCode === AGE_OPTION[1].value"
              v-model="ageMax"
              name=""
              id=""
              class="select mini"
            >
              <option
                v-for="age in ageMaxRange"
                :key="`ax_${age}`"
                :value="age"
              >
                {{ age }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">경력<i class="required"></i></div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="careerItem in CAREER_OPTION" :key="careerItem.name">
              <input
                v-model="careerCode"
                type="radio"
                class="checkbox"
                :id="careerItem.name"
                :value="careerItem.value"
              />
              <label :for="careerItem.name">{{ careerItem.name }}</label>
            </li>
            <!-- <li>
              <input type="checkbox" class="checkbox" id="cc1" checked />
              <label for="cc1">신입</label>
            </li>
            <li>
              <input type="checkbox" class="checkbox" id="cc2" />
              <label for="cc2">경력</label>
            </li>
            <li>
              <input type="checkbox" class="checkbox" id="cc3" />
              <label for="cc3">경력무관</label>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head" ref="nationalityText">
          국적<i class="required"></i>
        </div>
        <div class="t-doc">
          <div class="flex nationality">
            <select
              v-model="nationality"
              name=""
              id="nationality"
              ref="nationality"
              class="select w125"
            >
              <option value="">국적을 선택해주세요</option>
              <option
                v-for="(nation, idx) in nationalities"
                :key="`bt_${idx}`"
                :value="nation.code"
                :selected="nationality == nation.code"
              >
                {{ nation.codeValue }}
              </option>
            </select>
            <!--input
              v-model="nationality"
              type="text"
              class="text"
              ref="nationality"
              placeholder="대한민국"
            /-->
          </div>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">외국어</div>
        <div class="t-doc">
          <!--div class="flex">
            <input
              v-model="foreignLanguage"
              type="text"
              class="text"
              placeholder="한국어/중국어/영어"
            />
          </!--div-->
          <ul class="check_list">
            <li v-for="foreign in FOREIGN_OPTION" :key="foreign.name">
              <input
                v-model="foreign.on"
                type="checkbox"
                class="checkbox"
                :id="foreign.name"
              />
              <label :for="foreign.name">{{ foreign.name }}</label>
            </li>
          </ul>
        </div>
      </div>
    </fieldset>

    <h3 class="h3-tit">제공항목</h3>
    <fieldset class="fieldset">
      <div class="input_form">
        <div class="t-head">숙식지원</div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="supportItem in SUPPORT_OPTION" :key="supportItem.name">
              <input
                v-model="supportItem.on"
                type="checkbox"
                class="checkbox"
                :id="supportItem.name"
              />
              <label :for="supportItem.name">{{ supportItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">4대보험</div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="insItem in INSURANCE_OPTION" :key="insItem.name">
              <input
                v-model="insItem.on"
                type="checkbox"
                class="checkbox"
                :id="insItem.name"
              />
              <label :for="insItem.name">{{ insItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">보수,수당</div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="salItem in SALARY_OPTION" :key="salItem.name">
              <input
                v-model="salItem.on"
                type="checkbox"
                class="checkbox"
                :id="salItem.name"
              />
              <label :for="salItem.name">{{ salItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">직원복지</div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="welfareItem in WELFARE_OPTION" :key="welfareItem.name">
              <input
                v-model="welfareItem.on"
                type="checkbox"
                class="checkbox"
                :id="welfareItem.name"
              />
              <label :for="welfareItem.name">{{ welfareItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="input_form">
        <div class="t-head">우대사항</div>
        <div class="t-doc">
          <ul class="check_list">
            <li v-for="spcItem in TREAT_OPTION" :key="spcItem.name">
              <input
                v-model="spcItem.on"
                type="checkbox"
                class="checkbox"
                :id="spcItem.name"
              />
              <label :for="spcItem.name">{{ spcItem.name }}</label>
            </li>
          </ul>
        </div>
      </div>
    </fieldset>
    <div class="textarea-box">
      <h3 class="h3-tit">특이사항</h3>
      <textarea
        v-model="note"
        name=""
        id="textarea"
        cols="30"
        rows="10"
        class="textarea"
      ></textarea>
    </div>
  </div>
</template>

<script>
import {
  getExistJobConditionInfo,
  saveJobConditionInfo,
  getNationalities,
  // getForeignLanguages,
} from 'Api/modules'
import {
  GENDER_OPTION,
  AGE_OPTION,
  AGE_RANGE,
  CAREER_OPTION,
  FOREIGN_OPTION,
  SUPPORT_OPTION,
  INSURANCE_OPTION,
  SALARY_OPTION,
  WELFARE_OPTION,
  TREAT_OPTION,
} from 'Configs/registJob'
import { listToString, parseCodes } from 'Utils/codes'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      GENDER_OPTION,
      AGE_OPTION,
      AGE_RANGE: AGE_RANGE(),
      CAREER_OPTION,
      SUPPORT_OPTION: JSON.parse(JSON.stringify(SUPPORT_OPTION)),
      INSURANCE_OPTION: JSON.parse(JSON.stringify(INSURANCE_OPTION)),
      SALARY_OPTION: JSON.parse(JSON.stringify(SALARY_OPTION)),
      WELFARE_OPTION: JSON.parse(JSON.stringify(WELFARE_OPTION)),
      TREAT_OPTION: JSON.parse(JSON.stringify(TREAT_OPTION)),
      FOREIGN_OPTION: JSON.parse(JSON.stringify(FOREIGN_OPTION)),

      nationalities: [],
      // foreignLanguageList: [],

      jobConditionId: null,
      genderCode: GENDER_OPTION[0].value,
      ageCode: AGE_OPTION[0].value,
      ageMin: 18,
      ageMax: 18,
      careerCode: CAREER_OPTION[0].value,
      nationality: '',
      // foreignLanguage: '',
      // supportOptionCodes: '',
      // majorInsuranceCodes: '',
      // incentiveCodes: '',
      // welfareCodes: '',
      // spcCondCodes: '',
      note: '',
    }
  },
  computed: {
    ...mapGetters(['editingDocumentNo']),
    foreignLanguage() {
      return listToString(this.FOREIGN_OPTION)
    },
    supportOptionCodes() {
      return listToString(this.SUPPORT_OPTION)
    },
    majorInsuranceCodes() {
      return listToString(this.INSURANCE_OPTION)
    },
    incentiveCodes() {
      return listToString(this.SALARY_OPTION)
    },
    welfareCodes() {
      return listToString(this.WELFARE_OPTION)
    },
    spcCondCodes() {
      return listToString(this.TREAT_OPTION)
    },
    ageMaxRange() {
      if (this.ageMin) return this.AGE_RANGE.filter(age => age >= this.ageMin)
      return AGE_RANGE
    },
  },
  watch: {
    ageMin(newVal) {
      if (this.ageMax) {
        if (this.ageMin > this.ageMax) {
          this.ageMax = newVal
          this.$toasted.error(
            '최소 연령이 최대 연령보다 클 수 없습니다. 재설정 됩니다.',
          )
        }
      }
    },
    ageMax(newVal) {
      if (this.ageMin) {
        if (this.ageMin > this.ageMax) {
          this.ageMin = newVal
          this.$toasted.error(
            '최대 연령이 최소 연령보다 작을 수 없습니다. 재설정 됩니다.',
          )
        }
      }
    },
  },
  methods: {
    async getNationalityList() {
      const res = await getNationalities()
      if (res) this.nationalities = res
    },
    // todo:: 코드 서비스에서 가져오도록 향후 수정
    // async getForeignLanguageList() {
    // const res = await getForeignLanguages()
    // if (res) this.foreignLanguageList = res
    // },
    async fetchExistJobConditionInfo(documentNo) {
      const jobConditionInfo = await getExistJobConditionInfo({ documentNo })
      this.jobConditionId = jobConditionInfo.jobConditionId
      this.genderCode = jobConditionInfo.genderCode || GENDER_OPTION[0].value
      this.ageCode = jobConditionInfo.ageCode || AGE_OPTION[0].value
      this.ageMin = jobConditionInfo.ageMin
      this.ageMax = jobConditionInfo.ageMax
      this.careerCode = jobConditionInfo.careerCode || CAREER_OPTION[0].value
      this.nationality = jobConditionInfo.nationality
      // this.foreignLanguage = jobConditionInfo.foreignLanguage
      parseCodes(this.FOREIGN_OPTION, jobConditionInfo.foreignLanguage)
      parseCodes(this.SUPPORT_OPTION, jobConditionInfo.supportOptionCodes)
      parseCodes(this.INSURANCE_OPTION, jobConditionInfo.majorInsuranceCodes)
      parseCodes(this.SALARY_OPTION, jobConditionInfo.incentiveCodes)
      parseCodes(this.WELFARE_OPTION, jobConditionInfo.welfareCodes)
      parseCodes(this.TREAT_OPTION, jobConditionInfo.spcCondCodes)
      this.note = jobConditionInfo.note
    },

    validateInput(data) {
      if (!data.genderCode) return false
      if (data.ageCode === AGE_OPTION[1].value && !this.ageMin && !this.ageMax)
        return false
      if (!data.careerCode) return false
      if (!data.nationality) return false

      return true
    },
    async save() {
      // 필수항목 개별 정합성 및 문구 제공
      // 나이 정합성
      if (!this.ageCode) {
        this.$toasted.error('나이를 선택해주세요.')
        this.$refs['ageCode'].focus()
        return
      }

      // 나이 제한 정합성
      if (this.ageCode === AGE_OPTION[1].value) {
        if (this.ageMin === '') {
          this.$toasted.error('최소 나이를 선택해주세요.')
          return false
        }
        if (this.ageMax === '') {
          this.$toasted.error('최대 나이를 선택해주세요.')
          return false
        }
      }

      // 국가 정합성
      if (!this.nationality) {
        this.$toasted.error('국가를 입력해주세요.')
        await this.$refs['nationality'].focus()
        setTimeout(function () {
          // this.$refs['nationality'].blur()
          window.$('#nationality').blur()
        }, 500)
        // window.$('.nationality').focus()
        // await this.$refs['nationality'].blur()
        return
      }

      const saveData = {
        documentNo: this.editingDocumentNo,
        jobConditionId: String(this.jobConditionId),
        genderCode: this.genderCode,
        ageCode: this.ageCode,
        ageMin: this.ageCode === AGE_OPTION[1].value ? String(this.ageMin) : '',
        ageMax: this.ageCode === AGE_OPTION[1].value ? String(this.ageMax) : '',
        careerCode: this.careerCode,
        nationality: this.nationality,
        foreignLanguage: this.foreignLanguage,
        supportOptionCodes: this.supportOptionCodes,
        majorInsuranceCodes: this.majorInsuranceCodes,
        incentiveCodes: this.incentiveCodes,
        welfareCodes: this.welfareCodes,
        spcCondCodes: this.spcCondCodes,
        note: this.note,
      }

      // if (!this.validateInput(saveData)) {
      //   this.$toasted.error('필수 항목을 입력해주세요')
      //   return false
      // }

      try {
        await saveJobConditionInfo(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },

  async created() {
    //첫번째 입력란에 focus
    this.$nextTick(() => {
      this.$refs['genderCode_0'][0].focus()
    })

    if (this.editingDocumentNo)
      await this.fetchExistJobConditionInfo(this.editingDocumentNo)
    await this.getNationalityList()
    //await this.getForeignLanguageList()

    // 국적값이 null 이거나 undefined 이면, 선택 안내문구 로 체크되게.
    if (!this.nationality || this.nationality === '')
      this.nationality = this.$refs.nationality.children[0].value
  },
}
</script>

<style scoped>
.text-override {
  background: none;
}
.textarea-box .textarea {
  font-size: 14px;
  padding: 14px;
}
</style>
