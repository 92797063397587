import { RECRUIT_RESULT_OPTION } from 'Configs/registJob'
export default {
  methods: {
    setValues(res) {
      this.jobPostTitle = res.jobPostTitle
      this.companyName = res.companyName
      this.businessTypeName = res.businessTypeName
      this.jobManager = res.jobManager
      this.managerPhone = res.managerPhone
      this.workingAddress = res.workingAddress
      this.workingAddressDetail = res.workingAddressDetail
      this.jobPositionName = res.jobPositionName
      this.jobPositionDtlName = res.jobPositionDtlName
      this.positionCnt = res.positionCnt
      this.positionOptionNames = res.positionOptionNames
      this.positionAdditionalNames = res.positionAdditionalNames
      this.workPeriodType = res.workPeriodType
      this.workStartDate = res.workStartDate
      this.workEndDate = res.workEndDate
      this.catalogId = res.catalogId
      this.workStartTime = res.workStartTime
      this.workEndTime = res.workEndTime
      this.gender = res.gender
      this.age = res.age
      this.ageMin = res.ageMin
      this.ageMax = res.ageMax
      this.career = res.career
      this.nationality = res.nationality
      this.foreignLanguage = res.foreignLanguage
      this.supportOptions = res.supportOptions
      this.majorInsurances = res.majorInsurances
      this.incentives = res.incentives
      this.welfares = res.welfares
      this.spcConds = res.spcConds
      this.note = res.note
      this.boxProduct = res.boxProduct
      this.listupProduct = res.listupProduct
      this.pushProduct = res.pushProduct
      this.emphasisProducts = res.emphasisProducts
      this.openingTypeCode = res.openingTypeCode
      this.openingReservationDt = res.openingReservationDt
      this.closingDt = res.closingDt
      this.passDecisionType =
        res.passDecisionType || RECRUIT_RESULT_OPTION[0].value
      this.passDecisionTime = res.passDecisionTime
      this.payTotAmt = res.payTotAmt
      this.payVat = res.payVat
      this.productUseAmt = res.productUseAmt
      this.vat = res.vat
      this.matchingAmt = res.matchingAmt
      this.totalPayAmt = res.totalPayAmt + res.matchingAmt
    },
  },
}
