<template>
  <div class="inner job-posting">
    <sub-title :title="targetStep.title" :description="targetStep.description">
    </sub-title>
    <basic-info-form
      v-if="step === REGIST_STEP_NAME.BASIC_INFO"
    ></basic-info-form>
    <job-category-form
      v-else-if="step === REGIST_STEP_NAME.JOB_CATEGORY"
    ></job-category-form>
    <job-condition-form
      v-else-if="step === REGIST_STEP_NAME.JOB_CONDITION"
    ></job-condition-form>
    <product-select
      v-else-if="step === REGIST_STEP_NAME.PRODUCT_SELECT"
    ></product-select>
    <check-edit v-else-if="step === REGIST_STEP_NAME.CHECK_EDIT"></check-edit>
    <payment v-else-if="step === REGIST_STEP_NAME.PAYMENT"></payment>
    <regist-finish
      v-else-if="step === REGIST_STEP_NAME.REGIST_FINISH"
    ></regist-finish>
  </div>
</template>

<script>
import SubTitle from 'Common/SubTitle'
import BasicInfoForm from 'Components/registJob/content/BasicInfoForm'
import JobCategoryForm from 'Components/registJob/content/JobCategoryForm'
import JobConditionForm from 'Components/registJob/content/JobConditionForm'
import ProductSelect from 'Components/registJob/content/ProductSelect'
import CheckEdit from 'Components/registJob/content/CheckEdit'
import Payment from 'Components/registJob/content/Payment'
import RegistFinish from 'Components/registJob/content/RegistFinish'

import {
  REGIST_STEP_INFO,
  REGIST_STEP_ARRAY,
  REGIST_STEP_NAME,
} from 'Configs/registJob'

export default {
  components: {
    SubTitle,
    BasicInfoForm,
    JobCategoryForm,
    JobConditionForm,
    ProductSelect,
    CheckEdit,
    Payment,
    RegistFinish,
  },
  props: {
    step: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      REGIST_STEP_ARRAY: Object.freeze(REGIST_STEP_ARRAY),
      REGIST_STEP_NAME: Object.freeze(REGIST_STEP_NAME),
    }
  },
  computed: {
    targetStep() {
      return REGIST_STEP_INFO[this.step]
    },
  },
}
</script>

<style scoped>
/*모바일 화면에서 상단 여백 없어서 부여*/
@media (max-width: 1024px) {
  .inner.job-posting > .tit > button {
    margin-top: 2.66667vw;
  }

  /* 단가표 모바일에서 임시로 안보이게 처리 */
  /* main.sub .tit .btn.paylist-popup-btn {
    display: none;
  } */
}
</style>
