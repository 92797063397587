<template>
  <div class="sub-cont">
    <div class="yk-register-form-wrap type01">
      <div class="form-rows-wrap mt0">
        <div class="label str">개시일시</div>
        <div class="form-item-wrap date-type">
          <label
            v-for="(releaseItem, idx) in RELEASE_OPTION"
            :key="releaseItem.name"
          >
            <input
              type="radio"
              class="radio"
              name="radio"
              v-model="openingTypeCode"
              :ref="'releaseItem_' + idx"
              :value="releaseItem.value"
            />
            <span>{{ releaseItem.name }}</span>
          </label>

          <!-- 예약게시일 경우만 노출 -->
          <div class="date-item-wrap">
            <input
              type="text"
              class="inp datepicker"
              id="releaseStartDate"
              ref="releaseStartDate"
              :value="releaseStartDate"
              :disabled="openingTypeCode !== RELEASE_OPTION[1].value"
            />
            <select
              v-model="releaseStartTime"
              class="inp time-custom"
              ref="releaseStartTime"
              :disabled="openingTypeCode !== RELEASE_OPTION[1].value"
            >
              <option value="">선택</option>
              <option
                v-for="(releaseTime, idx) in RELEASE_TIME_LIST"
                :key="`rt_${idx}`"
                :value="releaseTime"
                :selected="releaseStartTime === releaseTime"
              >
                {{ releaseTime }}
              </option>
            </select>
            <!-- <input
              v-model="releaseStartTime"
              type="text"
              class="inp time"
              placeHolder="09"
              ref="releaseStartTime"
            />
            <span class="e01">시</span> -->
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label str">마감일시</div>
        <div class="form-item-wrap mt0 type01">
          <input
            type="text"
            class="inp datepicker"
            id="releaseEndDate"
            ref="releaseEndDate"
            :value="releaseEndDate"
          />
          <select
            v-model="releaseEndTime"
            class="inp time-custom"
            ref="releaseEndTime"
          >
            <option value="">선택</option>
            <option
              v-for="(releaseTime, idx) in RELEASE_TIME_LIST"
              :key="`rt_${idx}`"
              :value="releaseTime"
              :selected="releaseEndTime === releaseTime"
            >
              {{ releaseTime }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label str">노출위치<i class="required"></i></div>
        <div class="form-rows-wrap type01">
          <div class="form-item-wrap mt0">
            <div class="nor-value">구인 상품의 표시 영역을 선택하세요.</div>
          </div>
          <div class="form-item-wrap">
            <select v-model="boxProdId" class="select" ref="boxProdId">
              <option
                v-for="boxItem in boxProdList"
                :key="boxItem.productName"
                :value="boxItem.productCode"
              >
                {{
                  `${boxItem.productName} ---- ${insertCurrencyUnit(
                    boxItem.price,
                  )} / ${boxItem.period}일`
                }}
                <!-- 배너 BOX ---- 150,000 / 5일 -->
              </option>
            </select>
            <button
              type="button"
              class="btn-func"
              @click="showPreview(boxProdId)"
            >
              미리보기
            </button>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label str">리스트업</div>
        <div class="form-rows-wrap type01">
          <div class="form-item-wrap mt0">
            <div class="nor-value">
              구인 목록에 최상위로 주기적으로 표시할 수 있습니다.
            </div>
          </div>
          <div class="form-item-wrap">
            <select v-model="listupProdId" class="select" ref="listupProdId">
              <option
                v-for="listupItem in listupProdList"
                :key="listupItem.productName"
                :value="listupItem.productCode"
              >
                {{
                  `${listupItem.productName} ---- ${insertCurrencyUnit(
                    listupItem.price,
                  )} / ${listupItem.period}일`
                }}
                <!-- 12시간 LIST UP ---- 150,000 / 5일 -->
              </option>
            </select>
            <button
              type="button"
              class="btn-func"
              @click="showPreview(listupProdId)"
            >
              미리보기
            </button>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label str">강조옵션</div>
        <div class="form-rows-wrap type01">
          <div class="form-item-wrap mt0">
            <div class="nor-value">구인 목록의 제목을 강조할 수 있습니다.</div>
          </div>
          <div
            v-for="emphasisItem in emphasisProdList"
            :key="emphasisItem.productName"
            class="form-item-wrap mt10"
          >
            <a
              href="javascript:void(0)"
              class="btn-opt-toggle"
              :class="{ on: emphasisItem.on }"
              @click="toggleEmphasisItem(emphasisItem)"
            >
              <i class="el-icon-check"></i>
              <span
                >{{
                  `${emphasisItem.productName} (${insertCurrencyUnit(
                    emphasisItem.price,
                  )}) / ${emphasisItem.period}일`
                }}
                <!--아이콘 – VIP (100,000원 / 5일)-->
              </span>
            </a>
            <button
              type="button"
              class="btn-func"
              @click="showPreview(emphasisItem.productCode)"
            >
              미리보기
            </button>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label str">푸시옵션</div>
        <div class="form-rows-wrap type01">
          <div class="form-item-wrap mt0">
            <div class="nor-value">
              구인 목록을 주기적으로 PUSH 할 수 있습니다.
            </div>
          </div>
          <div class="form-item-wrap">
            <select v-model="pushProdId" class="select">
              <option
                v-for="pushItem in pushProdList"
                :key="pushItem.productName"
                :value="pushItem.productCode"
              >
                {{
                  `${pushItem.productName} ---- ${insertCurrencyUnit(
                    pushItem.price,
                  )} / ${pushItem.period}일`
                }}
                <!-- 12시간 PUSH ---- 150,000 / 5일 -->
              </option>
            </select>
            <!--button
              type="button"
              class="btn-func"
              @click="showPreview(pushProdId)"
            >
              미리보기
            </button-->
          </div>
        </div>
      </div>
      <!-- 합격결정 폼 이곳으로 이동함 -->
      <div class="form-rows-wrap">
        <div class="label str">합격결정</div>
        <div class="form-item-wrap date-type">
          <label
            v-for="resultOption in RECRUIT_RESULT_OPTION"
            :key="resultOption.name"
          >
            <input
              v-model="passDecisionType"
              type="radio"
              class="radio"
              :name="resultOption.name"
              :value="resultOption.value"
              @click="clearDecisionTime"
            />
            <span>{{ resultOption.name }}</span>
          </label>
          <div class="date-item-wrap">
            <span class="e01">마감일 이후</span>
            <input
              v-model="passDecisionTime"
              type="text"
              :readonly="passDecisionType === 'I'"
              class="inp time"
            />
            <span class="e01">시간내</span>
          </div>
        </div>
      </div>
    </div>

    <div class="prod-price-wrap">
      <div class="key">상품 금액</div>
      <div class="value">
        {{ insertCommaToNumber(sumSelectedProductPrice) }}
      </div>
      <!-- <div class="value">200,000원/5일</div> -->
    </div>
    <!-- <preview-popup
      :visible.sync="showPreviewPopup"
      :title="title"
      :imgPath="imgPath"
    ></preview-popup> -->
  </div>
</template>

<script>
//import PreviewPopup from '../popup/Preview'
import {
  getProductList, //상품목록 조회
  getExistProductInfo, //선택 상품 저장 조회
  saveProductInfo, //선택 상품 저장
  getPreviewProduct, //미리보기 : imgPath
} from 'Api/modules'
import {
  RELEASE_OPTION,
  TERM_OPTION,
  RELEASE_TIME,
  RECRUIT_RESULT_OPTION,
} from 'Configs/registJob'
import { listToString, parseCodes } from 'Utils/codes'
import { mapGetters } from 'vuex'
import datePickerMixin from 'Mixins/datePicker'
import { URL } from 'Api/api'
import moment from 'moment'

export default {
  components: {
    //PreviewPopup,
  },
  mixins: [datePickerMixin],
  data() {
    return {
      RECRUIT_RESULT_OPTION: Object.freeze(RECRUIT_RESULT_OPTION),
      showPreviewPopup: false,

      RELEASE_OPTION: JSON.parse(JSON.stringify(RELEASE_OPTION)),
      openingTypeCode: 'DIRECT',
      boxProdList: [], //{productCode, productName, price}
      listupProdList: [], //{productCode, productName, price}
      pushProdList: [], //{productCode, productName, price, period}
      emphasisProdList: [], //{productCode, productName, price}

      workPeriodType: '', //근무기간 유형
      releaseStartTime: '', //개시 시간
      releaseStartDate: '', //개시일시
      //openingReservationDt: '', //개시 일시, 시간
      releaseEndDate: '', //개시일시
      //closingDt: '', //마감 일시, 시간
      releaseEndTime: '', //마감 시간

      boxProdId: '',
      listupProdId: '',
      pushProdId: '',

      fetchedBoxProdId: '',
      fetchedListupProdId: '',
      fetchedPushProdId: '',

      //title: '',
      //fileId: '',
      //imgPath: '',

      period: '',
      RELEASE_TIME_LIST: Object.freeze(RELEASE_TIME),

      passDecisionType: RECRUIT_RESULT_OPTION[0].value, //합격 결정 유형
      passDecisionTime: '', //일괄 결정시 마감일 시간

      workStartDt: '', //근무시작일시(마감시간 정합성 위해)
      releaseOrigEndDate: '',
      releaseOrigEndTime: '',
    }
  },
  watch: {
    computedReleaseDays(newVal) {
      //개시 기간 계산 가능한 경우 상품 목록 조회
      if (
        !isNaN(newVal) &&
        newVal &&
        this.workPeriodType === TERM_OPTION[0].value
      ) {
        console.info('computedReleaseDays newVal=', newVal)
        this.fetchProductList() //상품 목록 조회
      }
    },
    openingTypeCode(newVal) {
      if (newVal === RELEASE_OPTION[0].value) this.releaseStartTime = ''
    },
    //1. 오늘과 비교해서 오늘보다 이전인 경우 : 오늘날짜로 설정한다
    //2. 마감 일자와 비교해서 마감보다 나중인 경우 : 나중(같은)날짜를 시작날짜로 설정한다.
    //=> 자동으로 마감일자는 오늘, 개시 일자보다 나중(같은)날짜가 된다.
    releaseStartDate(newVal) {
      if (newVal) {
        //현재 날짜와 비교
        if (!this.compareWithToday(newVal, this.releaseStartTime)) {
          this.setDatePickerDate(
            'releaseStartDate',
            new Date(),
            this.setStartDate,
          )
          this.releaseStartDate = moment().format('YYYY-MM-DD')
          if (this.releaseStartTime) this.releaseStartTime = ''
        }

        //endDate가 이미 세팅 되있는 경우
        if (this.releaseEndDate) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (
            this.diffTime(
              newVal + ' ' + this.releaseStartTime,
              this.releaseEndDate + ' ' + this.releaseEndTime,
            ) <= 0
          ) {
            this.setDatePickerDate('releaseEndDate', newVal, this.setEndDate)
            this.releaseEndDate = newVal
            this.releaseEndTime = ''
          }
        }
      }
    },
    //개시일자는 오늘보다 이전인 경우 위에서 맞춰지고, 시간을 현재시간 보다 이전으로 세팅할 경우 다시 선택하도록 비워준다.
    releaseStartTime(newVal) {
      // if (!this.releaseStartTime) {
      //   this.$toasted.error('예약개시시간을 입력해주세요.')
      //   this.$refs['releaseStartTime'].focus()
      //   return false
      // }
      if (newVal) {
        //현재날짜와 비교
        if (
          this.releaseStartDate &&
          !this.compareWithToday(this.releaseStartDate, newVal)
        ) {
          this.$toasted.error('예약개시 시간은 현재시간 이후여야 합니다.')
          this.$refs['releaseStartTime'].focus()
          this.releaseStartTime = ''
        }

        //예약개시인 경우 마감일시와 비교
        if (
          this.releaseStartDate &&
          this.releaseEndDate &&
          this.diffTime(
            this.releaseStartDate + ' ' + this.releaseStartTime,
            this.releaseEndDate + ' ' + this.releaseEndTime,
          ) <= 0
        ) {
          this.releaseEndTime = ''
        }
      }
    },
    //위에서 개시일자는 항상 현재시간보다 나중으로 설정되므로, 개시일자와 비교해서 개시일자가 더 나중일 경우 마감일자를 조정하고, 마감시간은 초기화 한다.
    releaseEndDate(newVal) {
      this.checkReleaseDt('DATE')
      if (newVal) {
        if (!this.compareWithToday(newVal, this.releaseEndTime)) {
          this.setDatePickerDate('releaseEndDate', new Date(), this.setEndDate)
          this.releaseEndDate = moment().format('YYYY-MM-DD')
          if (this.releaseEndTime) this.releaseEndTime = ''
        }

        //startDate가 이미 세팅 되있는 경우
        if (this.releaseStartDate) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (
            this.diffTime(
              this.releaseStartDate + ' ' + this.releaseStartTime,
              newVal + ' ' + this.releaseEndTime,
            ) <= 0
          ) {
            this.setDatePickerDate(
              'releaseEndDate',
              this.releaseStartDate,
              this.setEndDate,
            )
            this.releaseEndDate = this.releaseStartDate
            this.releaseEndTime = ''
          }
        }
      }
    },
    releaseEndTime(newVal) {
      this.checkReleaseDt('TIME')
      // if (!this.releaseEndTime) {
      //   this.$toasted.error('모집마감시간을 입력해주세요.')
      //   this.$refs['releaseEndTime'].focus()
      //   return false
      // }
      if (newVal) {
        if (
          this.releaseEndDate &&
          !this.compareWithToday(this.releaseEndDate, newVal)
        ) {
          this.$toasted.error('마감시간은 현재시간 이후여야 합니다.')
          this.$refs['releaseEndTime'].focus()
          this.releaseEndTime = ''
        }

        if (
          this.releaseEndDate &&
          this.releaseStartDate &&
          this.releaseStartTime &&
          this.diffTime(
            this.releaseStartDate + ' ' + this.releaseStartTime,
            this.releaseEndDate + ' ' + newVal,
          ) <= 0
        ) {
          this.releaseEndTime = ''
        }
      }
    },
  },
  computed: {
    ...mapGetters(['editingDocumentNo']),
    emphasisProdIds() {
      return listToString(this.emphasisProdList, 'productCode')
    },
    //개시 기간 계산
    computedReleaseDays() {
      return this.diffCalendar(this.releaseStartDate, this.releaseEndDate)
    },
    //상품 금액
    sumSelectedProductPrice() {
      let boxProdPrice = 0
      let listupProdPrice = 0
      let pushProdPrice = 0
      let emphasisPrice = 0

      if (
        this.boxProdList &&
        this.listupProdList &&
        this.pushProdList &&
        this.emphasisProdList
      ) {
        if (this.boxProdId) {
          const prod = this.boxProdList.find(
            item => item.productCode === this.boxProdId,
          )
          if (prod) boxProdPrice = prod ? prod.price : 0
        }
        if (this.listupProdId) {
          const prod = this.listupProdList.find(
            item => item.productCode === this.listupProdId,
          )
          if (prod) listupProdPrice = prod ? prod.price : 0
        }
        if (this.pushProdId) {
          const prod = this.pushProdList.find(
            item => item.productCode === this.pushProdId,
          )
          if (prod) pushProdPrice = prod.price
        }

        emphasisPrice = this.emphasisProdList
          .filter(item => item.on)
          .reduce((acc, item) => acc + item.price, 0)
      }

      return boxProdPrice + listupProdPrice + pushProdPrice + emphasisPrice
    },
    // imgPath() {
    //   if (this.fileId) return `${URL}/api/hgc/file/view/${this.fileId}`
    //   return ''
    // },
  },
  methods: {
    // 마감일시를 체크한다. 즉, 근무시작시간보다 이전이어야 한다.
    checkReleaseDt(dateType) {
      const releaseEndDt = this.releaseEndDate + ' ' + this.releaseEndTime
      const diffTime = this.diffTime(releaseEndDt, this.workStartDt)
      if (diffTime < 0) {
        this.$toasted.error(
          '모집마감일시는 근무시작시각[' +
            this.workStartDt +
            '] 이전이여야 합니다.',
        )
        if (dateType === 'DATE') {
          this.releaseEndDate = this.releaseOrigEndDate
          this.setDatePickerDate(
            'releaseEndDate',
            this.releaseEndDate,
            this.setEndDate,
          )
          this.$refs['releaseEndDate'].focus()
        } else if (dateType === 'TIME') {
          this.releaseEndTime = this.releaseOrigEndTime
          this.$refs['releaseEndTime'].focus()
        }
      }
    },
    clearDecisionTime() {
      this.passDecisionTime = ''
    },
    compareWithToday(targetDate, targetTime = '') {
      if (this.diffTime(new Date(), targetDate + ' ' + targetTime) < 0) {
        return false
      }
      return true
    },

    setStartDate(selectedDate) {
      this.releaseStartDate = selectedDate
    },

    setEndDate(selectedDate) {
      this.releaseEndDate = selectedDate
    },

    toggleEmphasisItem(emphasisItem) {
      const targetItem = this.emphasisProdList.find(
        item => item.productCode === emphasisItem.productCode,
      )
      if (targetItem) this.$set(targetItem, 'on', !targetItem.on)
    },

    async showPreview(productCode) {
      try {
        const { field, title } = await getPreviewProduct({ productCode })
        //this.title = title
        const imgPath = `${URL}/api/hgc/file/view/${field}`
        //this.showPreviewPopup = true
        this.$eventBus.$emit('popup:previewPopupVisible', {
          title,
          imgPath,
          width: '',
        })
      } catch (e) {
        console.error(e)
      }
    },

    async fetchProductList() {
      //근무기간 유형에 따라 상품 목록 조회 파라미터 다름
      this.period = ''

      //단기형 : 계산된 게시~마감 일 수를 파라미터로 보냄
      if (this.workPeriodType === TERM_OPTION[0].value)
        this.period = this.computedReleaseDays
      //장기형
      else if (this.workPeriodType === TERM_OPTION[1].value) this.period = 'L'
      //테마형
      else if (this.workPeriodType === TERM_OPTION[2].value) this.period = 'T'
      else {
        console.error('기간 에러')
        return
      }

      const { map } = await getProductList({ period: this.period })
      this.boxProdList = map.boxList
      //기본상품 디폴트로 선택
      this.boxProdId = '104'

      const defaultItem = {
        period: '',
        price: 0,
        productCategory: '',
        productCode: 'D',
        productName: '선택안함',
      }

      //선택/없음 항목 추가 필요
      this.listupProdList = map.listupList
      this.listupProdList.unshift(defaultItem)
      this.listupProdId = this.listupProdId || 'D'

      this.pushProdList = map.pushlist //map.pushList //푸시 상품 목록
      this.pushProdList.unshift(defaultItem)
      this.pushProdId = this.pushProdId || 'D'

      this.emphasisProdList = map.styleList
      this.emphasisProdList.forEach(item => this.$set(item, 'on', false))

      if (this.fetchedBoxProdId) this.boxProdId = this.fetchedBoxProdId

      if (this.fetchedListupProdId) this.listupProdId = this.fetchedListupProdId

      if (this.fetchedPushProdId) this.pushProdId = this.fetchedPushProdId

      if (this.fecthedEmphasisProdIds)
        parseCodes(
          this.emphasisProdList,
          this.fecthedEmphasisProdIds,
          'productCode',
        )
    },

    //기존 정보 저장 조회
    async fetchExistProductSelectInfo(documentNo) {
      const productSelectInfo = await getExistProductInfo({ documentNo })
      this.workPeriodType = productSelectInfo.workPeriodType //근무기간 유형
      //this.openingReservationDt = productSelectInfo.openingReservationDt

      if (
        productSelectInfo.openingTypeCode === 'RESERVATION' &&
        productSelectInfo.openingReservationDt
      ) {
        this.openingTypeCode = productSelectInfo.openingTypeCode
        const startDateTime = productSelectInfo.openingReservationDt.split(' ')
        this.releaseStartDate = startDateTime[0]
        // this.releaseStartTime = startDateTime[1].split(':')[0]
        this.releaseStartTime = startDateTime[1]
        this.setDatePickerDate(
          'releaseStartDate',
          this.releaseStartDate,
          this.setStartDate,
        )
      }

      //this.closingDt = this.productSelectInfo.closingDt
      if (productSelectInfo.closingDt) {
        if (this.diffTime(new Date(), productSelectInfo.closingDt) > 0) {
          const endDateTime = productSelectInfo.closingDt.split(' ')
          this.releaseEndDate = endDateTime[0]
          this.releaseEndTime = endDateTime[1]
          this.releaseOrigEndDate = endDateTime[0]
          this.releaseOrigEndTime = endDateTime[1]
          this.setDatePickerDate(
            'releaseEndDate',
            this.releaseEndDate,
            this.setEndDate,
          )
        }
      }

      //await this.fetchProductList() //상품 목록 조회

      this.fetchedBoxProdId =
        productSelectInfo.boxProdId === '0'
          ? '104'
          : productSelectInfo.boxProdId
      this.fetchedListupProdId =
        productSelectInfo.listupProdId === '0'
          ? '301'
          : productSelectInfo.listupProdId
      this.fetchedPushProdId =
        productSelectInfo.pushProdId === '0'
          ? 'D'
          : productSelectInfo.pushProdId
      this.fecthedEmphasisProdIds = productSelectInfo.emphasisProdIds
      // parseCodes(
      //   this.emphasisProdList,
      //   productSelectInfo.emphasisProdIds,
      //   'productCode',
      // )
      // 업무시작일 정보 (마감시간 정합성 때문: 마감시간은 최소 업무시작시간 이전이어야 함)
      this.workStartDt = productSelectInfo.workStartDt
      // 합격결정 사항
      this.passDecisionType =
        productSelectInfo.passDecisionType || RECRUIT_RESULT_OPTION[0].value
      this.passDecisionTime = productSelectInfo.passDecisionTime
        ? String(productSelectInfo.passDecisionTime)
        : ''
    },

    // validateInput() {
    //   if (
    //     !this.releaseStartDate &&
    //     !this.releaseStartTime
    //   ) {
    //     this.$toasted.error('예약개시인 경우, 예약일자와 시간을 선택해주세요.')
    //     this.$refs['releaseStartTime'].focus()
    //     return false
    //   }

    //   if (!this.releaseEndDate || !this.releaseEndTime) {
    //     this.$toasted.error('마감일시를 선택해주세요.')
    //     this.$refs['releaseEndTime'].focus()
    //     return false
    //   }
    //   return true
    // },

    async save() {
      // 예약개시 정합성 체크
      if (this.openingTypeCode == 'RESERVATION' && !this.releaseStartTime) {
        this.$toasted.error('예약개시인 경우, 예약일자와 시간을 선택해주세요.')
        this.$refs['releaseStartTime'].focus()
        return false
      }
      // 마감일시 정합성 체크
      if (!this.releaseEndDate || !this.releaseEndTime) {
        this.$toasted.error('마감일시를 선택해주세요.')
        this.$refs['releaseEndTime'].focus()
        return false
      }
      // 박스상품 선택 정합성 체크
      if (!this.boxProdId) {
        this.$toasted.error('박스상품을 선택해주세요.')
        this.$refs['boxProdId'].focus()
        return false
      }

      // 합격결정, 일괄결정인데, 시간 선택을 안하면 에러 처리
      if (
        this.passDecisionType === 'B' &&
        (!this.passDecisionTime || !this.passDecisionTime > 0)
      ) {
        this.$toasted.error('일괄결정시 마감일 이후 시간을 설정해 주세요.')
        this.$refs['passDecisionTime'].focus()
        return false
      }
      const saveData = {
        documentNo: this.editingDocumentNo,
        openingTypeCode: this.openingTypeCode,
        openingReservationDt:
          `${this.releaseStartDate} ${this.releaseStartTime}`
            ? `${this.releaseStartDate} ${this.releaseStartTime}`
            : '',
        closingDt: `${this.releaseEndDate} ${this.releaseEndTime}`,
        boxProdId: this.boxProdId,
        listupProdId: this.listupProdId === 'D' ? '' : this.listupProdId,
        pushProdId: this.pushProdId === 'D' ? '' : this.pushProdId,
        emphasisProdIds: this.emphasisProdIds,
        period: String(this.period),
        passDecisionType: this.passDecisionType,
        passDecisionTime: this.passDecisionTime,
      }

      // for debug
      // if (saveData) {
      //   return
      // }

      // if (!this.validateInput()) {
      //   this.$toasted.error('필수 항목을 입력해주세요')
      //   return false
      // }

      try {
        // const res = await saveProductInfo(saveData)
        await saveProductInfo(saveData)
        this.fetchedBoxProdId = ''
        this.fetchedListupProdId = ''
        this.fetchedPushProdId = ''
        this.fecthedEmphasisProdIds = ''
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    //첫번째 입력란에 focus
    this.$nextTick(() => {
      this.$refs['releaseItem_0'][0].focus()
    })

    this.initDatePicker('releaseStartDate', this.setStartDate)
    this.initDatePicker('releaseEndDate', this.setEndDate)

    //오늘 날짜로 디폴트 설정
    const today = moment().format('YYYY-MM-DD')
    this.setDatePickerDate('releaseStartDate', today, this.setStartDate)
    this.releaseStartDate = today
    this.setDatePickerDate('releaseEndDate', today, this.setEndDate)
    this.releaseEndDate = today

    this.workPeriodType = TERM_OPTION[0].value

    if (this.editingDocumentNo)
      await this.fetchExistProductSelectInfo(this.editingDocumentNo)
  },
}
</script>

<style lang="scss" scoped>
.yk-register-form-wrap .form-rows-wrap .form-item-wrap .inp.time-custom {
  margin-left: 8px;
  width: unset;
}
.yk-register-form-wrap.type01 .form-rows-wrap .form-item-wrap.date-type {
  margin-top: 0;
}
</style>
