<template>
  <div class="sub-cont">
    <div class="h2-tit">
      <h2>구인 직종</h2>
      <button class="btn" type="button" @click="openPayListPopup">
        단가표 보기
      </button>
    </div>

    <input-form title="직종" required>
      <select
        v-model="parentPositionId"
        name=""
        id=""
        class="select"
        ref="parentPositionId"
      >
        <option>직종을 선택하세요</option>
        <option
          v-for="(jobPosition, idx) in jobPositionList"
          :key="`jp_${idx}`"
          :value="jobPosition.jobPositionId"
        >
          {{ jobPosition.positionName }}
          <!-- {{
            `${jobPosition.positionName} : ${jobPosition.jobCategory} : ${jobPosition.jobCategoryName} `
          }} -->
        </option>
      </select>
    </input-form>

    <input-form v-if="jobDetailPositionList.length" title="세부직종">
      <select
        v-model="positionId"
        name=""
        id=""
        class="select"
        ref="positionId"
      >
        <option>세부직종을 선택하세요</option>
        <option
          v-for="(jobDetailPosition, idx) in jobDetailPositionList"
          :key="`jdp_${idx}`"
          :value="jobDetailPosition.jobPositionDtlId"
        >
          {{ jobDetailPosition.positionDtlName }}
          <!-- {{
            `${jobDetailPosition.positionName} : ${jobDetailPosition.jobCategory} : ${jobDetailPosition.jobCategoryName} `
          }} -->
        </option>
      </select>
    </input-form>

    <input-form
      v-if="optionCheckListLength > 0 || optionRadioListLength > 0"
      title="옵션"
      multi
    >
      <ul class="check_list">
        <li
          v-for="optionCheck in optionList.filter(
            item => item.optionType === OPTION_TYPE.CHECK,
          )"
          :key="'c_' + optionCheck.positionOptionId"
        >
          <input
            v-model="optionCheck.value"
            type="checkbox"
            class="checkbox"
            :id="'c_' + optionCheck.positionOptionId"
          />
          <label :for="'c_' + optionCheck.positionOptionId">{{
            optionCheck.optionName
          }}</label>
        </li>
        <!-- <li>
          <input type="checkbox" class="checkbox" id="c1" checked />
          <label for="c1">외국인 가능</label>
        </li>
        <li>
          <input type="checkbox" class="checkbox" id="c2" />
          <label for="c2">부부팀 가능</label>
        </li> -->
      </ul>
      <ul class="check_list">
        <li
          v-for="optionRadio in optionList.filter(
            item => item.optionType === OPTION_TYPE.RADIO,
          )"
          :key="'r_' + optionRadio.positionOptionId"
        >
          <input
            v-model="optionRadio.value"
            type="radio"
            class="radio"
            :id="'r_' + optionRadio.positionOptionId"
            name="radio"
            :value="optionRadio.positionOptionId"
          />
          <label :for="'r_' + optionRadio.positionOptionId">{{
            optionRadio.optionName
          }}</label>
        </li>
        <!-- <li>
          <input type="radio" class="radio" id="r1" name="radio" checked />
          <label for="r1">할당제</label>
        </li>
        <li>
          <input type="radio" class="radio" id="r2" name="radio" />
          <label for="r2">시간제</label>
        </li> -->
      </ul>
    </input-form>

    <input-form v-if="optionTextListLength > 0" title="추가정보">
      <div
        v-for="optionText in optionList.filter(
          item => item.optionType === OPTION_TYPE.TEXT,
        )"
        :key="`t_${optionText.positionOptionId}`"
        class="flex batting"
      >
        <span>{{ optionText.optionName }}</span>
        <input v-model="optionText.value" type="number" class="text" />
        <i>{{ optionText.optionUnit }}</i>
      </div>
    </input-form>

    <input-form title="근무기간" required multi>
      <ul class="check_list">
        <li
          v-for="(WORK_PERIOD_TYPE, idx) in WORK_PERIOD_TYPE_LIST"
          :key="`wpt_${idx}`"
        >
          <input
            v-model="workPeriodType"
            type="radio"
            class="radio"
            name="radior"
            :value="WORK_PERIOD_TYPE.value"
          />
          <label for="rr1">{{ WORK_PERIOD_TYPE.name }}</label>
        </li>
      </ul>
      <div class="flex">
        <div class="_calender">
          <div class="input-box">
            <input
              type="text"
              id="workStartDate"
              class="text datepicker"
              ref="workStartDate"
              :value="workStartDate"
              :disabled="dailyOccupationYn && workStartDate !== ''"
            />
          </div>
          <span>-</span>
          <div class="input-box">
            <input
              type="text"
              id="workEndDate"
              class="text datepicker"
              ref="workEndDate"
              :value="workEndDate"
              :disabled="dailyOccupationYn && workEndDate !== ''"
            />
          </div>
        </div>
        <div>
          <input
            v-model="dailyOccupationYn"
            type="checkbox"
            class="checkbox"
            :disabled="workPeriodType === 'LONG' || workPeriodType === 'THEME'"
            id="cc2"
          />
          <label for="cc2">일일직</label>
        </div>
      </div>
      <!-- 테마형인 경우에만 활성화 -->
      <div
        v-if="workPeriodType === 'THEME'"
        style="display: flex; align-items: end"
      >
        <div class="filebox">
          <input
            class="upload-name"
            v-model="attachedFileName"
            disabled="disabled"
          />
          <label for="ex_filename">사진 업로드</label>
          <input
            type="file"
            accept="image/*"
            ref="upload-file-input"
            id="ex_filename"
            class="upload-hidden"
            @change="onFileChanged"
          />
        </div>
        <button
          v-if="bannerImgPath"
          type="button"
          class="btn-func catalog"
          @click="showCatalog"
        >
          미리보기
        </button>
      </div>
    </input-form>

    <input-form title="근무시간" required>
      <div class="flex">
        <!--div class="input-time">
          <input
            type="text"
            class="text"
            v-model="workStartTime"
            ref="workStartTime"
          />
          <span>시</span>
        </!--div>
        <span>-</span>
        <div-- class="input-time">
          <input
            type="text"
            class="text"
            v-model="workEndTime"
            ref="workEndTime"
          />
          <span>시</span>
        </div-->
        <div class="input-box">
          <select
            v-model="workStartTime"
            name=""
            id=""
            class="select"
            ref="workStartTime"
          >
            <option
              v-for="(workTime, idx) in WORK_TIME_LIST"
              :key="`to_${idx}`"
              :value="workTime"
              :selected="workStartTime === workTime"
            >
              {{ workTime }}
            </option>
          </select>
        </div>
        <span>-</span>
        <div class="input-box">
          <select
            v-model="workEndTime"
            name=""
            id=""
            class="select"
            ref="workEndTime"
          >
            <option
              v-for="(workTime, idx) in WORK_TIME_LIST"
              :key="`to_${idx}`"
              :value="workTime"
              :selected="workEndTime === workTime"
            >
              {{ workTime }}
            </option>
          </select>
        </div>
      </div>
    </input-form>

    <input-form title="인원수" required>
      <div class="flex">
        <div class="input-calc">
          <button class="btn minus" @click="decreasePositionCnt">
            <img src="~assets/image/ico/ico-minus.svg" alt="" />
          </button>
          <input
            v-model="positionCnt"
            type="text"
            class="text"
            ref="positionCnt"
            placeholder="1"
          />
          <button class="btn plus" @click="increasePositionCnt">
            <img src="~assets/image/ico/ico-plus.svg" alt="" />
          </button>
        </div>
      </div>
    </input-form>

    <input-form title="급료" required>
      <div class="flex">
        <div class="input-pay">
          <input
            v-model="payAmt"
            type="text"
            class="text"
            ref="payAmt"
            placeholder="0"
            @keyup="formatPayAmt"
          />
          <span>원</span>
        </div>
      </div>
    </input-form>

    <div class="input_form_result">
      <div class="t-head">결제액</div>
      <div class="t-doc">
        <b>{{ insertCommaToNumber(sumPayAmt) }}</b>
        <span>(급료 X 근무기간 x 인원수)</span>
      </div>
    </div>

    <!-- <pay-list-table-popup :visible.sync="popupVisible"></pay-list-table-popup>

    <preview-popup
      :visible.sync="showCatalogPopup"
      :title="'카탈로그 이미지'"
      :imgPath="catalogPath"
      :maxWidth="'600px'"
    ></preview-popup> -->
  </div>
</template>

<script>
import {
  getJobPositionList,
  getJobPositionDetailList,
  getJobOptionList,
  getExistJobPositionInfo,
  saveJobPosition,
  uploadCatologFile,
} from 'Api/modules'
import InputForm from '../modules/InputForm'
import { mapGetters } from 'vuex'
import { TERM_OPTION, WORK_TIME } from 'Configs/registJob'
import datePickerMixin from 'Mixins/datePicker'
import { URL } from 'Api/api'
import moment from 'moment'

const OPTION_TYPE = {
  CHECK: 'C',
  RADIO: 'R',
  TEXT: 'T',
}

export default {
  components: {
    InputForm,
    //PreviewPopup: () => import('../popup/Preview'),
    //PayListTablePopup: () => import('Components/registJob/popup/PayListTable'),
  },
  mixins: [datePickerMixin],
  data() {
    return {
      showCatalogPopup: false,
      popupVisible: false,

      OPTION_TYPE: Object.freeze(OPTION_TYPE),
      WORK_PERIOD_TYPE_LIST: Object.freeze(TERM_OPTION),
      WORK_TIME_LIST: Object.freeze(WORK_TIME),
      attachedFileName: '', //첨부 파일 명
      maxFileSize: '',

      jobPositionList: [], //{jobPositionId, positionName, jobCategory, jobCategoryName, payAmt, registDt}
      jobDetailPositionList: [], //{jobPositionId, positionName, jobCategory, jobCategoryName, payAmt, registDt}

      optionList: [], //{positionOptionId, optionType, optionName, jobPositionId, optionUnit, registDt}

      parentPositionId: null,
      positionId: null, //세부직종 아이디
      fetchedPositionId: null, //저장조회로 가져온 세부직종 아이디
      workPeriodType: TERM_OPTION[0].value,
      workStartDate: '',
      workEndDate: '',
      dailyOccupationYn: false, //일일직 여부
      workStartTime: '',
      workEndTime: '',
      positionCnt: 1,
      payAmt: '0',
      //catalogFile: null,

      bannerImgPath: '', //기존 정보 조회시 첨부파일있는 경우 파일 접근하기 위한 파일 키값
      //checkedList: [], //{positionOptionId, jobPositionId, optionValue}
      fetchedCheckedList: [],
    }
  },
  watch: {
    workPeriodType(newVal) {
      if (newVal === 'LONG' || newVal === 'THEME') {
        this.dailyOccupationYn = false
      }
    },
    workStartDate(newVal) {
      const now = moment().format('YYYY-MM-DD')
      if (now > newVal) {
        this.$toasted.error(
          '근무기간 시작일은 오늘[' + now + '] 이후여야 합니다.',
        )
        this.$refs['workStartDate'].value = now
        this.$refs['workEndDate'].value = now
        this.workStartDate = now
        this.workEndDate = now
        return
      }

      this.workStartDate = newVal
      // this.workEndDate = newVal

      // if (newVal) {
      //endDate가 이미 세팅 되있는 경우
      // if (this.workEndDate) {
      //   //console.error(this.diffCalendar(newVal, this.workEndDate) - 1)
      //   //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
      //   if (this.diffCalendar(newVal, this.workEndDate) - 1 < 0) {
      //     this.setDatePickerDate('workEndDate', newVal, this.setEndDate)
      //     this.workEndDate = newVal
      //     this.$refs['workEndDate'].value = newVal
      //   }
      // }
      // 아래 로직때문에 workEndDate가 초기 로딩 시 계속 비워져 버그 발생
      // 일일직이 체크되어 있다면, workEndDate 를 동일한 값으로 세팅
      // if (this.dailyOccupationYn == true) {
      //   this.workEndDate = newVal
      //   this.$refs['workEndDate'].value = newVal
      // } else {
      //   this.workEndDate = ''
      //   this.$refs['workEndDate'].value = ''
      // }
      // }
    },
    workEndDate(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.workStartDate) {
          const diffDay = this.diffCalendar(this.workStartDate, newVal) - 1
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (diffDay < 0) {
            this.$toasted.error(
              '근무시작일자가 근무종료일자를 초과할 수 없습니다.',
            )
            this.workEndDate = moment().format('YYYY-MM-DD')
            return
            // this.setDatePickerDate('workStartDate', newVal, this.setStartDate)
            // this.workStartDate = newVal
          } else if (this.workPeriodType === 'SHORT' && diffDay > 7) {
            this.$toasted.error('근무기간이 7일을 초과할 수 없습니다.')
            this.workEndDate = this.workStartDate
            return
          }
        }
      }
    },
    // 일일직 선택이 되면, 시작일을 기준으로 종료일을 세팅한다.
    dailyOccupationYn() {
      if (this.dailyOccupationYn == true) {
        this.workEndDate = this.workStartDate
        this.$refs['workEndDate'].value = this.$refs['workStartDate'].value
      }
    },
    //직종 선택
    async parentPositionId(positionId) {
      if (positionId === '직종을 선택하세요') {
        this.jobDetailPositionList = []
        return
      }
      //세부 직종 목록 조회
      if (positionId) {
        this.jobDetailPositionList = await getJobPositionDetailList({
          positionId,
        })

        //세부직종이 없는 직종이라면 옵션 목록 조회
        if (this.jobDetailPositionList.length === 0) {
          //옵션 목록 조회
          await this.fetchJobOptionList(positionId)

          if (this.fetchedCheckedList && this.fetchedCheckedList.length) {
            this.parseCheckedList(this.fetchedCheckedList)
            //this.fetchedCheckedList = []
          }
        } else {
          this.positionId = this.jobDetailPositionList[0].jobPositionDtlId

          //저장 조회의 경우 세부직종 목록을 조회해 온 후 세부직종 아이디를 세팅해야한다.
          if (this.fetchedPositionId) {
            this.positionId = this.fetchedPositionId //세부직종 아이디 설정
            this.fetchedPositionId = null //조회 정보 초기화
          }
        }
      }
    },
    //세부직종 선택 : 직종에 따라 세부직종은 있을 수도, 없을 수도 있다.
    async positionId(positionId) {
      if (positionId === '세부직종을 선택하세요') {
        return
      }
      if (positionId) {
        //옵션 목록 조회
        await this.fetchJobOptionList(positionId)

        if (this.fetchedCheckedList && this.fetchedCheckedList.length) {
          this.parseCheckedList(this.fetchedCheckedList)
          //this.fetchedCheckedList = []
        }
      }
    },
    //인원수 숫자만 입력되게
    positionCnt(newVal) {
      this.positionCnt = parseInt(newVal, 10) || 0
    },
  },
  computed: {
    ...mapGetters(['editingDocumentNo']),

    //근무기간 계산 : 날짜 차이 + 1일
    sumWorkPeriod() {
      if (this.workStartDate && this.workEndDate)
        return this.diffCalendar(this.workStartDate, this.workEndDate)
      else return 0
    },

    //급료(payAmy) X 근무기간(sumWorkPeriod) x 인원수(positionCnt)
    sumPayAmt() {
      const sum =
        this.payAmt.replace(/,/gi, '') * this.sumWorkPeriod * this.positionCnt

      if (isNaN(sum)) return '0'
      else return String(sum)
    },

    optionCheckListLength() {
      return this.optionList.filter(
        item => item.optionType === OPTION_TYPE.CHECK,
      ).length
    },
    optionRadioListLength() {
      return this.optionList.filter(
        item => item.optionType === OPTION_TYPE.RADIO,
      ).length
    },
    optionTextListLength() {
      return this.optionList.filter(
        item => item.optionType === OPTION_TYPE.TEXT,
      ).length
    },

    checkedList() {
      return this.setCheckedList()
    },

    catalogPath() {
      if (this.bannerImgPath)
        return `${URL}/api/hgc/file/view/${this.bannerImgPath}`
      return null
    },
  },
  methods: {
    showCatalog() {
      //this.showCatalogPopup = true
      this.$eventBus.$emit('popup:previewPopupVisible', {
        title: '카탈로그 이미지',
        imgPath: this.catalogPath,
        width: '600px',
      })
    },
    openPayListPopup() {
      //this.popupVisible = true
      this.$eventBus.$emit('popup:payListPopupVisible')
    },

    findTargetJobPositionByPositionId(list, positionId) {
      return list.find(item => item.jobPositionId === positionId)
    },

    setStartDate(selectedDate) {
      this.workStartDate = selectedDate
    },

    setEndDate(selectedDate) {
      this.workEndDate = selectedDate
    },

    decreasePositionCnt() {
      if (this.positionCnt > 0) this.positionCnt--
    },

    increasePositionCnt() {
      this.positionCnt++
    },

    formatPayAmt() {
      this.payAmt = this.insertCommaToNumber(this.payAmt)
    },

    //저장 조회 값을 세팅
    async fetchJobPositionInfo(documentNo) {
      try {
        this.fetchedCheckedList = []
        const response = await getExistJobPositionInfo({ documentNo })
        const jobPositionInfo = response.jobPost

        // this.businessTypeObject = this.$refs.businessTypeObject.children[0].value
        // this.parentPositionId =
        //   jobPositionInfo.jobPositionId || this.parentPositionId //직종 아이디
        this.parentPositionId =
          jobPositionInfo.jobPositionId ||
          this.$refs.parentPositionId.children[0].value //직종 아이디
        if (this.parentPositionId === 0)
          this.parentPositionId = this.$refs.parentPositionId.children[0].value
        this.fetchedPositionId =
          jobPositionInfo.jobPositionDtlId || this.positionId //세부 직종 아이디 (positionId 세팅은 watch에서 함)
        const positionId = jobPositionInfo.jobPositionDtlId
          ? jobPositionInfo.jobPositionDtlId
          : jobPositionInfo.jobPositionId
        console.log(positionId)
        //if (positionId) await this.fetchJobOptionList(positionId) //watch에서 조회하게 되지만, 비동기 처리를 위해 여기서도 호출한다. 아래 checkedList 세팅하기 이전에 불러와져야하기 때문

        const targetTerm = TERM_OPTION.find(
          term => jobPositionInfo.workPeriodType === term.value,
        )
        if (targetTerm) this.workPeriodType = targetTerm.value
        if (jobPositionInfo.workStartDate) {
          this.setDatePickerDate(
            'workStartDate',
            jobPositionInfo.workStartDate,
            this.setStartDate,
          )
          this.workStartDate = jobPositionInfo.workStartDate
        }

        console.error(jobPositionInfo.workEndDate)

        if (jobPositionInfo.workEndDate) {
          this.setDatePickerDate(
            'workEndDate',
            jobPositionInfo.workEndDate,
            this.setEndDate,
          )
          this.workEndDate = jobPositionInfo.workEndDate
        }

        console.error(this.workEndDate)

        this.dailyOccupationYn =
          jobPositionInfo.dailyOccupationYn === 'Y' ? true : false
        this.workStartTime = jobPositionInfo.workStartTime
        this.workEndTime = jobPositionInfo.workEndTime

        this.positionCnt = jobPositionInfo.positionCnt || 1
        this.attachedFileName = jobPositionInfo.catalogFileName || '' // 파일명
        this.bannerImgPath = jobPositionInfo.bannerImgPath
        this.payAmt = jobPositionInfo.payAmt || '0'
        this.payAmt = this.insertCommaToNumber(this.payAmt)
        // if (response.checkedList && response.checkedList.length)
        //   this.parseCheckedList(response.checkedList)

        //값 세팅은 watch에서 수행
        this.fetchedCheckedList = response.checkedList
      } catch (e) {
        console.error(e)
      }
    },

    parseCheckedList(checkedList) {
      checkedList.forEach(item => {
        const target = this.optionList.find(
          option => option.positionOptionId === item.positionOptionId,
        )

        if (target) {
          if (target.optionType === OPTION_TYPE.CHECK)
            this.$set(target, 'value', true)
          else if (target.optionType === OPTION_TYPE.RADIO)
            this.$set(target, 'value', item.positionOptionId)
          else this.$set(target, 'value', item.optionValue)
        }
      })
    },

    //옵션 목록 조회
    async fetchJobOptionList(positionId) {
      try {
        this.optionList = await getJobOptionList({ positionId })
        this.optionList.forEach(option => {
          this.$set(option, 'value', '')
        })
      } catch (e) {
        console.error(e)
      }
    },

    setCheckedList() {
      let result = []
      result = this.optionList
        .filter(item => item.value)
        .map(item => {
          const { positionOptionId, value } = item
          let res = {
            positionOptionId: String(positionOptionId),
            //jobPositionId: String(jobPositionId),
            optionValue: '',
          }
          switch (item.optionType) {
            case OPTION_TYPE.CHECK:
            case OPTION_TYPE.RADIO:
              break
            case OPTION_TYPE.TEXT:
              res.optionValue = value
              break
            default:
              console.error('type error')
              break
          }
          return res
        })
      return result
    },

    validateInput(data) {
      if (!data.parentPositionId) return false
      if (this.jobDetailPositionList.length && !data.jobPositionId) return false
      if (!data.workStartDate) return false
      if (!data.workEndDate) return false
      if (!this.workStartTime) return false
      if (!this.workEndTime) return false
      if (!this.positionCnt) return false
      if (!this.payAmt) return false

      return true
    },

    //근무 직종 저장
    async save() {
      // 정합성 시작.
      // 직종 정합성
      if (!this.parentPositionId || this.parentPositionId == 0) {
        this.$toasted.error('직종을 선택해주세요.')
        this.$refs.parentPositionId.focus()
        return false
      }
      // 근무기간 시작일 정합성
      if (!this.workStartDate) {
        this.$toasted.error('근무기간 시작일을 선택해주세요.')
        this.$refs.workStartDate.focus()
        return false
      }
      // 근무기간 종료일 정합성
      if (!this.workEndDate) {
        this.$toasted.error('근무기간 종료일을 선택해주세요.')
        this.$refs.workEndDate.focus()
        return false
      }
      // 근무시작시간 정합성
      if (!this.workStartTime) {
        this.$toasted.error('근무시작 시간을 선택해주세요.')
        this.$refs.workStartTime.focus()
        return false
      }
      // 근무종료시간 정합성
      if (!this.workEndTime) {
        this.$toasted.error('근무종료 시간을 선택해주세요.')
        this.$refs.workEndTime.focus()
        return false
      }
      // 인원수 정합성
      if (this.positionCnt === 0) {
        this.$toasted.error('모집인원수를 입력해주세요.')
        this.$refs.positionCnt.focus()
        return false
      }
      // 급료 정합성.
      if (this.payAmt <= 0) {
        this.$toasted.error('급료를 입력해주세요.')
        this.$refs.payAmt.focus()
        return false
      }
      //
      // if (!this.validateInput(saveData)) {
      //   this.$toasted.error('필수 항목을 입력해주세요')
      //   return false
      // }
      const saveData = {
        documentNo: this.editingDocumentNo,
        parentPositionId: String(this.parentPositionId),
        jobPositionId: String(this.positionId),
        workPeriodType: this.workPeriodType,
        workStartDate: this.workStartDate,
        workEndDate: this.workEndDate,
        dailyOccupationYn: this.dailyOccupationYn ? 'Y' : 'N',
        workStartTime: this.workStartTime,
        workEndTime: this.workEndTime,
        positionCnt: String(this.positionCnt),
        payAmt: String(this.payAmt.replace(/,/gi, '')),
        //catalogFile: this.catalogFile,
        bannerImgPath:
          this.workPeriodType === 'THEME' ? String(this.bannerImgPath) : '',
        checkedList: this.checkedList,
      }

      try {
        // const res = await saveJobPosition(saveData)
        this.fetchedCheckedList = []
        await saveJobPosition(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    //파일 첨부
    onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      const file = event.target.files[0]
      if (file) {
        //파일 크기 제한
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
        }

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
          'application/pdf',
        ].includes(file.type)

        if (isAcceptable) {
          this.attachedFileName = file.name
          //this.catalogFile = file
          this.uploadCatologFile(file)
        } else {
          //this.catalogFile = null
          this.attachedFileName = ''
          this.$refs['upload-file-input'].value = ''
        }
      }
    },
    async uploadCatologFile(uploadFile) {
      const res = await uploadCatologFile({ uploadFile })
      this.bannerImgPath = res
    },
  },
  async created() {
    //첫번째 입력란에 focus
    this.$nextTick(() => {
      this.$refs['parentPositionId'].focus()
    })

    //직종 목록 조회
    this.jobPositionList = await getJobPositionList()
    if (this.jobPositionList.length)
      this.parentPositionId = this.jobPositionList[0].jobPositionId
    // this.parentPositionId = this.$refs.parentPositionId.children[0].value

    //작업/수정/복사 중인 데이터가 있는 경우, 이전 단계로 돌아온 경우 : 저장 조회
    if (this.editingDocumentNo) {
      await this.fetchJobPositionInfo(this.editingDocumentNo)
    }

    this.initDatePicker('workStartDate', this.setStartDate)
    this.initDatePicker('workEndDate', this.setEndDate)

    // todo:: 포커싱이 상단으로 가도록
  },
}
</script>

<style lang="scss">
.input_form .t-doc > div.flex .select {
  margin-left: 0;
  width: 100%;
}
.btn-func.catalog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  background: #8e8bad;
  border: 0;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
  @media (max-width: 1024px) {
    font-size: 3.73333vw;
    width: 25vw;
    height: 10.66667vw;
  }
}
</style>
