<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info :open="navOpen">
      <sub-nav :step="registJobStep" @toggle="onNavToggle"></sub-nav>
    </sub-nav-with-user-info>
    <main class="sub">
      <regist-job-layout
        ref="regist-job-layout"
        :step="registJobStep"
      ></regist-job-layout>
      <step-button-nav
        v-if="registJobStep !== REGIST_STEP_NAME.REGIST_FINISH"
        :step="registJobStep"
        @prevStep="moveToPrevStep"
        @nextStep="moveToNextStep"
        @payment="onPayment"
        @paymentComplete="onPaymentComplete"
      ></step-button-nav>
    </main>
    <previous-notice-popup
      :visible.sync="previousNoticeVisible"
      @editRecruit="editRecruit"
      @copyRecruit="copyRecruit"
    ></previous-notice-popup>
    <pay-list-table-popup
      :visible.sync="payListPopupVisible"
    ></pay-list-table-popup>
    <preview-popup
      :visible.sync="previewPopupVisible"
      :title="previewTitle"
      :imgPath="previewImgPath"
      :maxWidth="previewWidth"
    ></preview-popup>
  </section>
</template>
<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import SubNav from 'Components/registJob/partials/SubNav'
import RegistJobLayout from 'Components/registJob/RegistJobLayout'
import StepButtonNav from 'Components/registJob/partials/StepButtonNav'

import { REGIST_STEP_NAME, REGIST_STEP_ARRAY } from 'Configs/registJob'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    SubNavWithUserInfo,
    RegistJobLayout,
    SubNav,
    StepButtonNav,
    PreviousNoticePopup: () =>
      import('Components/registJob/popup/PreviousNotice'),
    PreviewPopup: () => import('Components/registJob/popup/Preview'),
    PayListTablePopup: () => import('Components/registJob/popup/PayListTable'),
  },
  data() {
    return {
      REGIST_STEP_NAME: Object.freeze(REGIST_STEP_NAME),
      navOpen: false,
      previousNoticeVisible: false,
      payListPopupVisible: false,
      previewPopupVisible: false,

      previewTitle: '',
      previewImgPath: '',
      previewWidth: '',
      returnUrl: this.$route.query.returnUrl,
    }
  },
  computed: {
    ...mapGetters(['registJobStep']),
  },
  methods: {
    ...mapMutations(['SET_STEP', 'RESET_EDITING_DOCUMENT_NO']),
    onNavToggle() {
      this.navOpen = !this.navOpen
    },
    moveToPrevStep(targetIndex) {
      if (targetIndex === 0) return
      //이전 단계 이동
      this.SET_STEP(REGIST_STEP_ARRAY[targetIndex - 1])
    },
    async moveToNextStep(targetIndex) {
      if (targetIndex === REGIST_STEP_ARRAY.length - 1) return

      try {
        //단계 데이터 저장
        const res = await this.saveStepData(targetIndex)

        //다음 단계 이동
        if (res) this.SET_STEP(REGIST_STEP_ARRAY[targetIndex + 1])
        //else console.error('step not saved') //에러 발생
      } catch (e) {
        console.error(e)
      }
    },
    async onPaymentComplete(targetIndex) {
      if (targetIndex === REGIST_STEP_ARRAY.length - 1) return

      try {
        //단계 데이터 저장
        // const res = await this.saveStepData(targetIndex)

        //다음 단계 이동
        this.SET_STEP(REGIST_STEP_ARRAY[targetIndex + 1])
        //else console.error('step not saved') //에러 발생
      } catch (e) {
        console.error(e)
      }
    },
    async saveStepData() {
      return await this.$refs['regist-job-layout'].$children[1].save()
    },
    editRecruit(documentNo) {
      this.$eventBus.$emit('editRecruit', documentNo)
    },
    copyRecruit(documentNo) {
      this.$eventBus.$emit('copyRecruit', documentNo)
    },
    openPreviousNoticePopup() {
      this.previousNoticeVisible = true
    },
    openPreviewPopup({ title, imgPath, width }) {
      this.previewPopupVisible = true
      this.previewTitle = title
      this.previewImgPath = imgPath
      this.previewWidth = width
    },
    openPayListPopup() {
      this.payListPopupVisible = true
    },
    onPayment() {
      this.$eventBus.$emit('payment')
    },
  },
  created() {
    if (this.isEmployee == true) {
      // location.href = '/regist-job'
      // location.href = '/'
      this.$toasted.error(
        `일자리등록은 기업회원 전용입니다. 메인페이지로 이동합니다.`,
      )
      location.href = '/'
    }
    this.$eventBus.$on(
      'popup:previousNoticeVisible',
      this.openPreviousNoticePopup,
    )
    this.$eventBus.$on('popup:previewPopupVisible', this.openPreviewPopup)
    this.$eventBus.$on('popup:payListPopupVisible', this.openPayListPopup)
  },
  // 해당 페이지 나갈 시 단계 데이터 초기화
  beforeDestroy() {
    this.$eventBus.$off(
      'popup:previousNoticeVisible',
      this.openPreviousNoticePopup,
    )
    this.$eventBus.$off('popup:previewPopupVisible', this.openPreviewPopup)
    this.$eventBus.$off('popup:payListPopupVisible', this.openPayListPopup)

    this.SET_STEP(REGIST_STEP_ARRAY[0])
    this.RESET_EDITING_DOCUMENT_NO() //작성/수정 중인 일자리 id 초기화
  },
}
</script>
