<template>
  <div class="sub-nav">
    <h2 class="">일자리 등록</h2>
    <!-- 일자리 등록완료 타이틀은 표시 X -->
    <p v-if="targetIndex < 6" class="tit" ref="tit">
      {{ `${targetIndex + 1}.${REGIST_STEP_INFO[step].title}` }}
    </p>
    <ul ref="ul">
      <li
        v-for="(stepName, idx) in registStepNavArray"
        :class="{ on: step === stepName }"
        :key="`rsa-${idx}`"
      >
        <a>{{ REGIST_STEP_INFO[stepName].title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  REGIST_STEP_NAME,
  REGIST_STEP_INFO,
  REGIST_STEP_ARRAY,
} from 'Configs/registJob'
import { mapMutations } from 'vuex'

export default {
  props: {
    step: {
      type: String,
    },
  },
  data() {
    return {
      REGIST_STEP_NAME: Object.freeze(REGIST_STEP_NAME),
      REGIST_STEP_INFO: Object.freeze(REGIST_STEP_INFO),
      targetIndex: null,
    }
  },
  watch: {
    step(newVal) {
      this.targetIndex = REGIST_STEP_ARRAY.findIndex(step => newVal === step)
    },
  },
  computed: {
    registStepNavArray() {
      const arr = JSON.parse(JSON.stringify(REGIST_STEP_ARRAY))
      arr.pop()
      return arr
    },
  },
  methods: {
    ...mapMutations(['SET_STEP']),
    onClickTit() {
      //모바일 레이아웃에서만 실행
      // if (matchMedia('screen and (max-width: 1024px)').matches) {
      //   this.$refs['tit'].classList.toggle('open')
      //   this.$refs['ul'].classList.toggle('on')
      //   this.$emit('toggle')
      // }
    },
    onClickStep(step) {
      this.onClickTit()
      this.SET_STEP(step)
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-nav {
  > p.tit {
    background: none;
    background-color: #fff2ef;
    cursor: default;
  }
}
</style>
