<template>
  <div class="input_form" :class="{ multi }">
    <div class="t-head">
      {{ title }}
      <i v-if="required" class="required"></i>
    </div>
    <div class="t-doc">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
