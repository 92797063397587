<template>
  <div class="sub-cont">
    <div class="h2-tit type01">
      <h2 class="mshow">결제내역&#40;급료&#41;</h2>
    </div>

    <div class="yk-buy-item-wrap">
      <div class="yk-buy-item">
        <ul>
          <li v-for="(displayNon, idx) in displayNon" :key="`pi_${idx}`">
            <div class="key">
              {{ displayNon.productName }}
            </div>
            <div class="value">
              <strong>{{ insertCommaToNumber(displayNon.salesAmt) }}</strong
              >원
            </div>
          </li>
          <li class="yk-buy-item-total">
            <div class="key" style="color: #111; font-weight: 500">
              합계<span style="color: #e76950; font-weight: 500"
                >&#09;&#40;해당 금액은 근무 완료 후 결제&#41;</span
              >
            </div>
            <div class="value">
              <strong style="color: #111; font-weight: 500">{{
                insertCommaToNumber(totalSalary)
              }}</strong
              >원
            </div>
          </li>
          <!-- 기타 이용료 & 할인 항목 -->
          <!-- <li>
          <div class="key">시스템 이용료</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(systemUseFee) }}</strong
            >원
          </div>
        </li>
        <li>
          <div class="key">서비스 이용료</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(serviceUseFee) }}</strong
            >원
          </div>
        </li>
        <li>
          <div class="key">할인</div>
          <div class="value">
            <strong>- {{ insertCommaToNumber(discountAmt) }}</strong
            >원
          </div>
        </li>
        <li>
          <div class="key">VAT</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(vat) }}</strong
            >원
          </div>
        </li> -->
        </ul>
      </div>
    </div>
    <div class="h2-tit type01">
      <h2 class="mshow">결제내역&#40;상품&#41;</h2>
    </div>
    <div class="yk-buy-item-wrap">
      <div class="yk-buy-item">
        <ul>
          <li v-for="(display, idx) in display" :key="`pi_${idx}`">
            <div class="key">
              {{ display.productName }}
            </div>
            <div class="value">
              <strong>{{ insertCommaToNumber(display.salesAmt) }}</strong
              >원
            </div>
          </li>
          <li class="yk-buy-item-total">
            <div class="key" style="color: #111; font-weight: 500">합계</div>
            <div class="value">
              <strong style="color: #111; font-weight: 500">{{
                insertCommaToNumber(totalProducts)
              }}</strong
              >원
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="h2-tit">
      <h2 class="mshow">결제상품정보</h2>
    </div>
    <div class="yk-register-form-wrap">
      <div class="form-rows-wrap mt0">
        <div class="label">상품금액</div>
        <div class="form-item-wrap">
          <div class="value">
            <strong>{{ insertCommaToNumber(totalProducts) }}</strong>
          </div>
        </div>
      </div>
      <!--div class="form-rows-wrap">
        <div class="label">포인트사용</div>
        <div class="form-item-wrap">
          <div class="_number">
            <input v-model="coinUseAmt" type="text" class="inp price" />
          </div>
          <div class="coin-value">
            <span>보유포인트</span>
            <strong>{{ insertCommaToNumber(userCoinAmt) }}</strong>
          </div>
        </div>
      </div-->
      <div class="form-rows-wrap">
        <div class="label">결제금액</div>
        <div class="form-item-wrap">
          <div class="value">
            <strong>{{ insertCommaToNumber(totalProducts) }}</strong>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">결제방법</div>
        <div class="form-item-wrap">
          <label v-for="payTypeItem in PAY_TYPE_OPTION" :key="payTypeItem.name">
            <input
              v-model="paymentType"
              type="radio"
              class="radio"
              name="radio"
              :value="payTypeItem.value"
              disabled
            />
            <span>{{ payTypeItem.name }}</span>
          </label>
        </div>
      </div>
      <!-- <div class="form-rows-wrap">
        <div class="label none">&nbsp;</div>
        <div class="form-item-wrap">
          <div id="card-slide" class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(cardItem, idx) in cardList"
                :key="`cdn_${idx}`"
                class="swiper-slide"
              >
                <a href="javascript:void(0)">
                  <div class="card-name">{{ cardItem.cardName }}</div>
                  <div class="card-num">{{ cardItem.cardNo }}</div>
                </a>
              </div>
              <div class="swiper-slide">&nbsp;</div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div> -->
    </div>
    <iframe
      name="HiddenIFrame"
      id="HiddenIFrame"
      width="1027"
      height="1000"
      style="display: none"
      v-if="!isApp"
    ></iframe>
  </div>
</template>

<script>
// import Swiper JS, styles
// import Swiper from 'swiper'
// import 'swiper/css/swiper.css'
import { getPaymentInfo, doPayment } from 'Api/modules'
import { PAY_TYPE_OPTION, PAY_METHOD_OPTION } from 'Configs/registJob'
import { mapGetters } from 'vuex'
import { sendMessageToApp } from 'Utils/message'
import { URL } from 'Api/api'
// import serialize from 'form-serialize'
export default {
  data() {
    return {
      PAY_TYPE_OPTION: PAY_TYPE_OPTION,
      PAY_METHOD_OPTION: PAY_METHOD_OPTION,
      payMethod: PAY_METHOD_OPTION[1].value,
      paymentType: PAY_TYPE_OPTION[0].value,

      sales: [], //{productName, salesAmt}
      totPayAmt: 0,
      userCoinAmt: 0,

      productName: '',
      //legacy
      // productList: [], //{productCode, produtName, price}
      // systemUseFee: 0,
      // serviceUseFee: 0,
      // discountAmt: 0,
      // vat: 0,
      // purchaseAmt: 0, //구매금액
      // totPayAmt: 0,
      // cardList: [], //{cardName, cardNo}

      // coinId: '',
      // balance: 0,

      //paymentAmt: 0,  //결제금액
      coinUseAmt: 0,
      userId: null,
    }
  },
  computed: {
    ...mapGetters(['editingDocumentNo', 'account']),
    //결제금액
    paymentAmt() {
      //구매금액 - 코인사용금액
      return Number(this.totPayAmt) - Number(this.coinUseAmt)
    },
    displayNon: function () {
      return this.sales.filter(function (displayNon) {
        return displayNon.productType !== 'DISPLAY'
      })
    },
    display: function () {
      return this.sales.filter(function (display) {
        return display.productType === 'DISPLAY'
      })
    },
    totalProducts() {
      return this.display.reduce((sum, display) => {
        return sum + display.salesAmt
      }, 0)
    },
    totalSalary() {
      return this.displayNon.reduce((sum, displayNon) => {
        return sum + displayNon.salesAmt
      }, 0)
    },
  },
  watch: {
    coinUseAmt(newVal) {
      if (newVal) {
        if (this.coinUseAmt > this.userCoinAmt)
          this.coinUseAmt = this.userCoinAmt
      }
    },
    paymentType(newVal) {
      this.$eventBus.$emit('chnBtn', newVal)
    },
  },
  methods: {
    async fetchPaymentInfo(documentNo) {
      const res = await getPaymentInfo({ documentNo })
      this.sales = res.sales
      this.totPayAmt = res.totPayAmt
      this.userCoinAmt = res.userCoinAmt
      this.productName = res.productName
      this.paymentType = res.paymentMethod
      // this.productList = res.list
      // this.systemUseFee = res.systemUseFee
      // this.serviceUseFee = res.serviceUseFee
      // this.discountAmt = res.discountAmt
      // this.vat = res.value
      // this.purchaseAmt = res.purchaseAmt
      // this.totPayAmt = res.totPayAmt
      // this.cardList = res.cardList
    },
    async save() {
      try {
        // const res = await doPayment({
        await doPayment({
          documentNo: this.editingDocumentNo,
          //purchaseAmt: this.purchaseAmt, //구매금액
          paymentAmt: String(this.paymentAmt), //결제금액
          coinUseAmt: String(this.coinUseAmt), //코인사용
          //userId: this.account.userId,
        })
        return true
      } catch (e) {
        console.error(e)
      }
    },
    async payment() {
      // 우리쪽 컨트롤러를 통한 PG 호출
      const paymentPgUrl = URL + '/api/v1/payments/pg'
      const payForm = document.createElement('form')
      payForm.method = 'POST'

      // 주문번호(구인공고 문서번호)
      //fixme:: 문서번호와 결제로그아이디로 Moid 를 만든다.
      const documentNo = document.createElement('input')
      documentNo.type = 'hidden'
      documentNo.name = 'documentNo'
      documentNo.value = this.editingDocumentNo
      payForm.appendChild(documentNo)

      // 거래상품명
      const goodsName = document.createElement('input')
      goodsName.type = 'hidden'
      goodsName.name = 'GoodsName'
      goodsName.value = this.editingDocumentNo
      payForm.appendChild(goodsName)

      // 회원사고객 아이디
      const userId = document.createElement('input')
      userId.type = 'hidden'
      userId.name = 'mallUserID'
      userId.value = this.account.userId
      payForm.appendChild(userId)

      // 구매자명
      const userNm = document.createElement('input')
      userNm.type = 'hidden'
      userNm.name = 'BuyerName'
      userNm.value = this.account.userNm
      payForm.appendChild(userNm)

      // 사용 포인트
      // const usedPoint = document.createElement('input')
      // usedPoint.type = 'hidden'
      // usedPoint.name = 'usedPoint'
      // usedPoint.value = '10'
      // payForm.appendChild(usedPoint)

      // 거래금액
      const amt = document.createElement('input')
      amt.type = 'hidden'
      amt.name = 'Amt'
      amt.value = this.paymentAmt
      // amt.value = 1000
      payForm.appendChild(amt)

      // 지불수단
      const payMethod = document.createElement('input')
      payMethod.type = 'hidden'
      payMethod.name = 'PayMethod'
      payMethod.value = 'CARD'
      payForm.appendChild(payMethod)

      // 디바이스 유형
      const deviceType = document.createElement('input')
      deviceType.type = 'hidden'
      deviceType.name = 'deviceType'
      deviceType.value = this.isMobileDevice()
      payForm.appendChild(deviceType)

      // 추가 정보(결제결과 화면 분기용 => 1:일자리 등록 결제, 2:정산내역 결제)
      const paymentPath = document.createElement('input')
      paymentPath.type = 'hidden'
      paymentPath.name = 'paymentPath'
      paymentPath.value = '1'
      payForm.appendChild(paymentPath)

      document.body.appendChild(payForm)

      //모바일 앱이 아닐 경우 - 팝업(모바일 웹브라우저, 웹브라우저)
      sendMessageToApp('LOG', { isApp: this.isApp })
      if (this.isMobileDevice() == 'MOBILE') {
        payForm.target = ''
        // 모바일 결제중 결제 취소시 따로 화면 개발 필요함. (StopURL 값을 전달해야 한다. 서버에서 하기로 함)
        const stopUrl = document.createElement('input')
        stopUrl.type = 'hidden'
        stopUrl.name = 'StopURL'
        stopUrl.value = document.location.origin
        payForm.appendChild(stopUrl)
      } else {
        payForm.target = 'HiddenIFrame'
      }

      sendMessageToApp('LOG', { form: 'pay submit start.' })

      payForm.action = paymentPgUrl
      payForm.submit()
    },
  },
  async created() {
    sendMessageToApp('LOG', {
      created: 'created',
      isApp: this.isApp,
      query: this.$route.query,
    })
    this.$eventBus.$on('payment', this.payment)

    if (this.editingDocumentNo)
      await this.fetchPaymentInfo(this.editingDocumentNo)
  },
  beforeDestroy() {
    this.$eventBus.$off('payment', this.payment)
  },
  mounted() {
    //swiper 초기화
    // this.$nextTick(() => {
    //   new Swiper('#card-slide', {
    //     slidesPerView: '2',
    //     spaceBetween: 60,
    //     navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //     },
    //     breakpoints: {
    //       320: {
    //         slidesPerView: '1',
    //         spaceBetween: 60,
    //       },
    //       // when window width is >= 640px
    //       769: {
    //         slidesPerView: '2',
    //         spaceBetween: 60,
    //       },
    //     },
    //   })
    // })
  },
}
</script>

<style></style>
