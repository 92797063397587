<template>
  <div class="sub-cont">
    <div class="h2-tit">
      <h2>검토 및 수정</h2>
    </div>

    <div class="yk-register-form-wrap">
      <div class="form-rows-wrap mt0">
        <div class="label">제목</div>
        <div class="form-item-wrap">
          <input v-model="jobPostTitle" type="text" class="inp" readonly />
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">근무지</div>
        <div class="form-rows-wrap type01">
          <div class="form-item-wrap mt0">
            <div class="value">
              <strong>{{ companyName }}</strong>
              <!-- 회사명 -->
              <span>( {{ businessTypeName }} )</span>
              <!-- 숙박 유형 -->
            </div>
          </div>
          <div class="form-item-wrap input-form">
            <div class="slabel t-head">담당자<i class="required"></i></div>
            <input
              v-model="jobManager"
              type="text"
              class="inp name"
              placeholder="담당자 이름을 입력해주세요"
              ref="jobManager"
            />
          </div>
          <div class="form-item-wrap">
            <div class="slabel">연락처<i class="required"></i></div>
            <input
              v-model="managerPhone"
              type="text"
              class="inp tel"
              placeholder="담당자 연락처를 입력해주세요"
              ref="managerPhone"
            />
          </div>
          <div class="form-item-wrap">
            <div class="slabel">근무지</div>
            <input
              type="text"
              class="inp addr"
              readonly
              :value="`${workingAddress} ${workingAddressDetail}`"
            />
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">구인직종</div>
        <div class="form-item-wrap">
          <div class="nor-value">
            <div
              class="cnt"
              v-if="
                positionOptionNames !== '' && positionAdditionalNames !== ''
              "
            >
              {{
                `${jobPositionName} ${jobPositionDtlName} ${positionCnt}명 (${positionOptionNames}, ${positionAdditionalNames})`
              }}
              <!-- 룸메이드 클리너 2명 (외국인가능, 시간제, 배팅2명) -->
            </div>
            <div
              class="cnt"
              v-else-if="
                positionOptionNames !== '' && positionAdditionalNames === ''
              "
            >
              {{
                `${jobPositionName} ${jobPositionDtlName} ${positionCnt}명 (${positionOptionNames})`
              }}
              <!-- 룸메이드 클리너 2명 (외국인가능, 시간제, 배팅2명) -->
            </div>
            <div class="cnt" v-else>
              {{ `${jobPositionName} ${jobPositionDtlName} ${positionCnt}명` }}
            </div>
            <div class="date">
              <strong>{{ `근무기간: ${workPeriodType}` }}</strong>
              <span>
                {{
                  `(${workStartDate} ~ ${workEndDate}, ${workStartTime}~${workEndTime})`
                }}
                <!-- (2021.07.21 ~ 2021.07.31, 10시~16시) -->
              </span>
              <!-- <button
                type="button"
                class="btn-func catalog"
                @click="showCatalog"
              >
                카탈로그
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">근무조건</div>
        <div class="form-item-wrap">
          <div class="nor-value" v-if="foreignLanguage !== ''">
            {{
              `성별: ${gender}, 나이: ${age}${ageRange}, 경력: ${career}, 국가: ${nationality}, 외국어: ${foreignLanguage}`
            }}
            <!-- 성별: 성별무관, 나이: 나이무관, 경력: 경력무관, 국가: 국적무관,
            제공항목: 숙식제공 -->
          </div>
          <div class="nor-value" v-else>
            {{
              `성별: ${gender}, 나이: ${age}${ageRange}, 경력: ${career}, 국가: ${nationality}`
            }}
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">
          <img src="~assets/image/ico/ico-work-label01.png" />
          <span>숙식지원</span>
        </div>
        <div v-if="supportOptions" class="form-item-wrap">
          <ul class="vlist">
            <li
              v-for="supportItem in supportOptions.split(',')"
              :key="supportItem"
            >
              {{ supportItem }}
            </li>
            <!-- <li>숙소제공</li>
            <li>식사-식비제공</li>
            <li>출퇴근</li> -->
          </ul>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">
          <img src="~assets/image/ico/ico-work-label02.png" />
          <span>4대보험</span>
        </div>
        <div v-if="majorInsurances" class="form-item-wrap">
          <ul class="vlist">
            <li v-for="insItem in majorInsurances.split(',')" :key="insItem">
              {{ insItem }}
            </li>
            <!-- <li>국민연금</li>
            <li>건강보험</li>
            <li>고용보험</li>
            <li>산재보험</li> -->
          </ul>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">
          <img src="~assets/image/ico/ico-work-label03.png" />
          <span>보수,수당</span>
        </div>
        <div v-if="incentives" class="form-item-wrap">
          <ul class="vlist">
            <li v-for="incenItem in incentives.split(',')" :key="incenItem">
              {{ incenItem }}
            </li>
            <!-- <li>인센티브제</li>
            <li>정기보너스</li>
            <li>휴가비지원</li>
            <li>퇴직금</li> -->
          </ul>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">
          <img src="~assets/image/ico/ico-work-label04.png" />
          <span>직원복지</span>
        </div>
        <div v-if="welfares" class="form-item-wrap">
          <ul class="vlist">
            <li v-for="welfItem in welfares.split(',')" :key="welfItem">
              {{ welfItem }}
            </li>
            <!-- <li>경조금</li>
            <li>경조휴가제</li>
            <li>건강검진</li>
            <li>정기휴가</li> -->
          </ul>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">
          <!-- <img src="~assets/image/ico/ico-work-label04.png" /> -->
          <span>우대사항</span>
        </div>
        <div v-if="spcConds" class="form-item-wrap">
          <ul class="vlist">
            <li v-for="spcItem in spcConds.split(',')" :key="spcItem">
              {{ spcItem }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">특이사항</div>
        <div class="form-item-wrap">
          <input
            v-model="note"
            type="text"
            class="inp"
            readonly
            placeholder=""
          />
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">상품선택</div>
        <div class="form-item-wrap">
          <div class="nor-value">{{ productListString }}</div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">개시일시</div>
        <div class="form-item-wrap">
          <div class="nor-value">
            {{
              `${RELEASE_OPTION_VALUE[openingTypeCode]} ${openingReservationDt}`
            }}
          </div>
        </div>
        <!-- <div class="form-item-wrap date-type">
          <label>
            <input type="radio" class="radio" name="radio" />
            <span>즉시개시</span>
          </label>
          <label>
            <input type="radio" class="radio" name="radio" />
            <span>예약개시</span>
          </label>
          <div class="date-item-wrap">
            <input type="text" class="inp datepicker" value="2021-12-01" />
            <input type="text" class="inp time" value="14" />
            <span class="e01">시</span>
          </div>
        </div> -->
      </div>
      <div class="form-rows-wrap">
        <div class="label">마감일시</div>
        <div class="form-item-wrap">
          <div class="nor-value">
            {{ closingDt }}
          </div>
          <!-- <input type="text" class="inp datepicker" value="2021-12-01" />
          <input type="text" class="inp time" value="14" />
          <span class="e01">시</span> -->
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">합격결정</div>
        <div class="form-item-wrap date-type">
          <label v-if="passDecisionType === 'I'">즉시결정</label>
          <template v-else>
            <label>일괄결정</label>
            <span class="e01">{{
              `마감일 이후 ${passDecisionTime} 시간내`,
            }}</span>
          </template>
        </div>
      </div>
    </div>

    <div class="yk-total-item-wrap">
      <div class="label">결제금액</div>
      <ul class="value-list">
        <li>
          <div class="key">급료</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(payTotAmt) }}</strong> 원
          </div>
        </li>
        <li>
          <div class="key">급료수수료(VAT)</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(payVat) }}</strong> 원
          </div>
        </li>
        <li>
          <div class="key">매칭수수료</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(matchingAmt) }}</strong> 원
          </div>
        </li>
        <li>
          <div class="key">상품이용료</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(productUseAmt) }}</strong> 원
          </div>
        </li>
        <li class="last">
          <div class="key">합계</div>
          <div class="value">
            <strong>{{ insertCommaToNumber(totalPayAmt) }}</strong> 원
          </div>
        </li>
      </ul>
    </div>
    <!-- <preview-popup
      :visible.sync="showCatalogPopup"
      :title="''"
      :imgPath="catalogPath"
    ></preview-popup> -->
  </div>
</template>

<script>
import { getReviewInfo, saveReviewInfo } from 'Api/modules'
import { mapGetters } from 'vuex'
import { TERM_OPTION, RELEASE_OPTION_VALUE } from 'Configs/registJob'
import checkEditMixin from 'Mixins/checkEdit'
import { URL } from 'Api/api'

export default {
  components: {
    //PreviewPopup : () => import ('../popup/Preview'),
  },
  mixins: [checkEditMixin],
  data() {
    return {
      showCatalogPopup: false,
      RELEASE_OPTION_VALUE: Object.freeze(RELEASE_OPTION_VALUE),
      TERM_OPTION: Object.freeze(TERM_OPTION),

      jobPostTitle: '',
      companyName: '',
      businessTypeName: '',
      jobManager: '', //채용담당자
      managerPhone: '', //채용담당자 연락처
      workingAddress: '',
      workingAddressDetail: '',
      jobPositionName: '',
      jobPositionDtlName: '',
      positionCnt: 0,
      positionOptionNames: '', //어떤 형태로 내려오는지?
      positionAdditionalNames: '',
      workPeriodType: '',
      workStartDate: '',
      workEndDate: '',
      //catalogPath: '', //카탈로그 파일 이미지 경로
      catalogId: '',
      workStartTime: '',
      workEndTime: '',
      gender: '',
      age: '',
      ageMin: '',
      ageMax: '',
      career: '',
      nationality: '',
      foreignLanguage: '',
      supportOptions: '',
      majorInsurances: '',
      incentives: '',
      welfares: '',
      spcConds: '',
      note: '',
      boxProduct: '',
      listupProduct: '',
      pushProduct: '',
      emphasisProducts: '',
      openingTypeCode: '',
      openingReservationDt: '',
      closingDt: '',
      passDecisionType: '', //합격 결정 유형
      passDecisionTime: '', //일괄 결정시 마감일 시간
      payTotAmt: 0,
      payVat: 0,
      productUseAmt: 0,
      vat: 0,
      matchingAmt: 0,
      totalPayAmt: 0,
    }
  },
  computed: {
    ...mapGetters(['editingDocumentNo']),
    ageRange() {
      if (this.ageMin && this.ageMax) {
        return `(${this.ageMin}~${this.ageMax})`
      }
      return ''
    },
    productListString() {
      let filteredList = [
        this.boxProduct,
        this.listupProduct,
        this.pushProduct,
        this.emphasisProducts,
      ].filter(
        product => product !== undefined && product !== null && product !== '',
      )
      return filteredList.join(', ')
    },
    catalogPath() {
      if (this.catalogId) return `${URL}/api/hgc/file/view/${this.catalogId}`
      return null
    },
  },
  methods: {
    showCatalog() {
      this.showCatalogPopup = true
    },
    async fetchReviewInfo(documentNo) {
      const res = await getReviewInfo({ documentNo })
      this.setValues(res)
    },

    //필수 입력값 검사
    validCheck() {
      if (!this.jobManager) {
        this.$toasted.error('담당자 이름을 입력해주세요.')
        this.$refs['jobManager'].focus()
        return false
      }
      if (!this.managerPhone) {
        this.$toasted.error('담당자 연락처를 입력해주세요.')
        this.$refs['managerPhone'].focus()
        return false
      }
      // if (!/^[0-9]*$/.test(this.phone)) {
      //   this.$toasted.error('담당자 연락처 형식이 올바르지 않습니다.')
      //   this.$refs['managerPhone'].focus()
      //   return false
      // }
      return true
    },

    async save() {
      if (!this.validCheck()) return

      const saveData = {
        documentNo: this.editingDocumentNo,
        jobManager: this.jobManager,
        managerPhone: this.managerPhone,
      }
      try {
        await saveReviewInfo(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    //첫번째 입력란 focus
    this.$nextTick(() => {
      this.$refs['jobManager'].focus()
    })

    if (this.editingDocumentNo)
      await this.fetchReviewInfo(this.editingDocumentNo)
  },
}
</script>

<style lang="scss" scoped>
.date {
  button.catalog.btn-func {
    margin-top: 8px;
    margin-left: 0;
  }
}
</style>
