<template>
  <div class="btn-wrap">
    <a
      href="javascript:;"
      v-if="targetIndex > 0"
      class="link left"
      @click="moveToPrevStep"
    >
      <span>이전</span>
    </a>
    <!--a
      href="javascript:;"
      class="link right"
      @click="payment"
      v-if="step === 'PAYMENT'"
    >
      <span>결제하기</span>
    </a-->
    <a
      class="link right pointer"
      @click="payment"
      v-if="step === 'PAYMENT' && paymentType === 1"
    >
      <span>결제하기</span>
    </a>
    <a
      class="link right pointer"
      @click="moveToNextStep"
      v-else-if="step === 'PAYMENT' && paymentType === 2"
    >
      <span>등록하기</span>
    </a>
    <!--a
      href="javascript:;"
      class="link right"
      @click="moveToNextStep"
      v-if="step === 'PAYMENT'"
    >
      <span>결제하기</span>
    </a-->
    <a href="javascript:;" class="link right" @click="moveToNextStep" v-else>
      <span>다음</span>
    </a>
  </div>
</template>

<script>
import { REGIST_STEP_ARRAY } from 'Configs/registJob'

export default {
  props: {
    step: {
      type: String,
    },
  },
  data() {
    return {
      targetIndex: 0,
      paymentType: 1,
    }
  },
  watch: {
    step(newVal) {
      this.targetIndex = REGIST_STEP_ARRAY.findIndex(step => newVal === step)
    },
  },
  methods: {
    moveToPrevStep() {
      this.$emit('prevStep', this.targetIndex)
    },
    moveToNextStep() {
      this.$emit('nextStep', this.targetIndex)
    },
    payment() {
      this.$emit('payment')
    },
    chnBtn(val) {
      this.paymentType = val
    },
  },
  async created() {
    this.$eventBus.$on('chnBtn', this.chnBtn)
    // 결제후 화면 제어를 위해서 이곳에 리스터를 연결함.
    window.addEventListener('message', async msg => {
      // let res
      const { resultCode } = msg.data
      // 페이지 이동
      if (resultCode === '3001') {
        this.$emit('paymentComplete', this.targetIndex)
      }
      return
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('chnBtn', this.chnBtn)
  },
}
</script>

<style lang="scss" scoped>
main.sub .btn-wrap .link {
  margin: 0 5px;
}
main.sub .btn-wrap .link.left {
  display: flex;
  border: solid 1px #8e8bad;
}
.pointer {
  cursor: pointer;
}
</style>
