<template>
  <div class="sub-cont">
    <div class="h2-tit">
      <h2>구인공고 제목</h2>
      <button class="btn" type="button" @click="openPreviousNoticePopup">
        이전 공고 불러오기
      </button>
    </div>

    <div class="input_form">
      <div class="t-head">
        제목
        <i class="required"></i>
      </div>
      <div class="t-doc">
        <input
          v-model="jobPostTitle"
          type="text"
          class="text"
          ref="jobPostTitle"
          placeholder="제목을 입력하세요"
        />
      </div>
    </div>
    <div class="input_form">
      <div class="t-head">
        호텔명
        <i class="required"></i>
      </div>
      <div class="t-doc">
        <input
          v-model="hotelName"
          type="text"
          class="text"
          ref="hotelName"
          placeholder="호텔명을 입력하세요"
        />
      </div>
    </div>
    <div class="input_form itemTop" style="flex-wrap: wrap; align-items: start">
      <div class="t-head">호텔 사진</div>
      <div style="display: flex; align-items: end" class="photo-file-con">
        <div class="filebox">
          <input
            class="upload-name"
            v-model="attachedFileName"
            disabled="disabled"
            readonly="readonly"
          />
          <label for="ex_filename" class="ex_filename">사진 업로드</label>
          <input
            type="file"
            accept="image/*"
            ref="upload-file-input"
            id="ex_filename"
            class="upload-hidden"
            @change="onFileChanged"
          />
        </div>
        <!-- <button
          v-if="catalogId"
          type="button"
          class="btn-func catalog"
          @click="showCatalog"
        >
          미리보기
        </button> -->
        <div class="profile-item-wrap" style="margin-left: 0">
          <!-- 사진 제거 UI 추가 -->
          <div class="pic" :style="userImg">
            <!-- <button type="button" class="btn-file-delete" @click="deletePhoto">
              <i class="el-icon-close"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="input_form multi">
      <div class="t-head">
        근무지역
        <i class="required"> </i>
      </div>
      <div class="t-doc">
        <div class="rd" v-if="!isBasicAddressDisabled">
          <input
            type="radio"
            name="r1"
            class="radio"
            id="r1"
            v-model="addressRadio"
            :disabled="isBasicAddressDisabled"
            :value="BASIC_ADDRESS"
          />
          <label for="r1">기본주소</label>
        </div>
        <div class="t1" v-if="!isBasicAddressDisabled">
          {{ `${basicAddress} ${basicAddressDetail}` }}
        </div>
        <div class="rd">
          <input
            type="radio"
            name="r1"
            class="radio"
            id="r2"
            v-model="addressRadio"
            :value="NEW_ADDRESS"
          />
          <label for="r2">신규주소</label>
        </div>
        <div class="search" @click="findAddress">
          <input
            v-model="workingAddress"
            type="text"
            class="text"
            placeholder="주소 검색"
            readonly
            ref="workingAddress"
          />
          <button type="button">
            <i class="el-icon-search"></i>
          </button>
        </div>
        <div>
          <input
            v-model="workingAddressDetail"
            type="text"
            class="text address-detail"
            placeholder="상세 주소"
            :readonly="!workingAddress"
            ref="workingAddressDetail"
          />
        </div>
      </div>
    </div>
    <div class="input_form">
      <div class="t-head">
        숙박업 유형
        <i class="required"></i>
      </div>
      <div class="t-doc">
        <select
          v-model="businessTypeObject"
          name=""
          id="businessTypeId"
          class="select"
          ref="businessTypeObject"
        >
          <option>호텔 유형을 선택하세요</option>
          <option
            v-for="(typeOption, idx) in businessTypeList"
            :key="`to_${idx}`"
            :value="typeOption"
          >
            {{ typeOption.codeValue }}
          </option>
        </select>
      </div>
    </div>
    <!-- <previous-notice-popup
      :visible.sync="previousNoticeVisible"
      @editRecruit="editRecruit"
      @copyRecruit="copyRecruit"
    ></previous-notice-popup> -->
  </div>
</template>

<script>
import {
  getExistAddress,
  getBusinesTypeList,
  saveJobLocation,
  getExistJobLocation,
  copyPreviousRecruit,
  uploadCatologFile,
} from 'Api/modules'
import { URL } from 'Api/api'
import { mapMutations, mapGetters } from 'vuex'
import { searchAddressGetResult } from 'Utils/message'
import { USER_TYPE } from 'Configs/user'

export default {
  components: {
    // PreviousNoticePopup: () =>
    //   import('Components/registJob/popup/PreviousNotice'),
  },
  data() {
    return {
      previousNoticeVisible: false,

      BASIC_ADDRESS: Object.freeze('BASIC_ADDRESS'),
      NEW_ADDRESS: Object.freeze('NEW_ADDRESS'),
      isBasicAddressDisabled: false,
      addressRadio: this.BASIC_ADDRESS,

      jobPostTitle: '',
      hotelName: '',
      basicAddress: '',
      basicAddressDetail: '',
      workingAddress: '',
      workingAddressDetail: '',
      region1DepthName: '',
      region2DepthName: '',
      region3DepthName: '',
      businessTypeObject: null,

      businessTypeList: [],

      isSaved: false, //근무지 저장을 완료하고, 일자리 등록 진행을 계속하느라 이탈하는지 그냥 이탈하는지 구분하기 위한 플래그

      attachedFileName: '', //첨부 파일 명
      catalogId: '', //기존 정보 조회시 첨부파일있는 경우 파일 접근하기 위한 파일 키값
      // showCatalogPopup: false,
      photo: '', //사진 파일
      isDelete: false,
    }
  },
  computed: {
    ...mapGetters(['editingDocumentNo', 'account', 'accountUserImg']),
    catalogPath() {
      if (this.catalogId) return `${URL}/api/hgc/file/view/${this.catalogId}`
      return null
    },
    userImg() {
      let photo = ''
      if (this.isDelete) {
        photo =
          this.account.userType === USER_TYPE.EMPLOYEE
            ? require('assets/image/etc/etc-user-noimg.svg')
            : require('assets/image/etc/etc-hotel-noimg.svg')
      } else {
        // photo = this.inputPhoto || this.accountUserImg
        photo =
          this.catalogId === ''
            ? this.inputPhoto || this.accountUserImg
            : `${URL}/api/hgc/file/view/${this.catalogId}`
      }
      //console.info('this.photo', this.photo)
      return `background: url(${photo}) no-repeat center center;`
    },
  },
  methods: {
    ...mapMutations(['SET_EDITING_DOCUMENT_NO', 'RESET_EDITING_DOCUMENT_NO']),
    openPreviousNoticePopup() {
      //this.previousNoticeVisible = true
      this.$eventBus.$emit('popup:previousNoticeVisible', true)
    },
    // deletePhoto() {
    //   this.isDelete = true
    //   this.deleteAttachedFile()
    // },
    findAddress() {
      this.addressRadio = this.NEW_ADDRESS

      if (this.isApp) {
        searchAddressGetResult(({ sido, sigungu, bname, address }) => {
          this.region1DepthName = sido
          this.region2DepthName = sigungu
          this.region3DepthName = bname
          this.workingAddress = address
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { sido, sigungu, bname, address } = data
            this.region1DepthName = sido
            this.region2DepthName = sigungu
            this.region3DepthName = bname
            this.workingAddress = address
            return
          },
        }).open()
      }
    },
    validateInput(data) {
      let ok = true
      for (let value of Object.values(data)) {
        if (value === '' || value === null) {
          ok = false
          break
        }
      }
      return ok
    },
    async save() {
      const saveData = {
        jobPostTitle: this.jobPostTitle,
        hotelName: this.hotelName,
        workingAddress:
          this.addressRadio === this.BASIC_ADDRESS
            ? this.basicAddress
            : this.workingAddress,
        workingAddressDetail:
          this.addressRadio === this.BASIC_ADDRESS
            ? this.basicAddressDetail
            : this.workingAddressDetail,
        region1DepthName: this.region1DepthName,
        region2DepthName: this.region2DepthName,
        region3DepthName: this.region3DepthName,
        businessTypeCode: this.businessTypeObject.code,
        businessTypeName: this.businessTypeObject.codeValue,
        catalogId: this.catalogId,
        attachedFileName: this.attachedFileName,
      }

      //입력 오류 :: fixme: 상세화
      // 제목 정합성 체크
      if (!this.jobPostTitle) {
        this.$toasted.error('제목을 입력해주세요.')
        this.$refs['jobPostTitle'].focus()
        return
      }
      // 근무지역 정합성 체크
      if (this.addressRadio === 'NEW_ADDRESS' && !this.workingAddress) {
        this.$toasted.error('근무지역을 입력해주세요.')
        this.$refs['workingAddress'].focus()
        return
      }
      // 숙박업 유형 정합성 체크
      if (!this.businessTypeObject || !this.businessTypeObject.codeValue) {
        this.$toasted.error('숙박업 유형을 선택해주세요.')
        this.$refs['businessTypeObject'].focus()
        return
      }
      // if (!this.validateInput(saveData)) {
      //   this.$toasted.error('필수 항목을 입력해주세요')
      //   return false
      // }
      //수정인 경우
      if (this.editingDocumentNo) saveData.documentNo = this.editingDocumentNo

      try {
        const res = await saveJobLocation(saveData)
        this.isSaved = true
        this.SET_EDITING_DOCUMENT_NO(res)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    //복사하는 경우 데이터만 조회하고, documentNo는 저장시 새로 발급된다.
    async copyRecruit(documentNo) {
      const copiedDocumentNo = await copyPreviousRecruit({ documentNo })
      this.SET_EDITING_DOCUMENT_NO(copiedDocumentNo) //수정중인 documentNo 저장
      this.fetchJobLocationInfo(copiedDocumentNo)
    },
    //수정하는 경우 데이터를 조회하고, 저장 시 documentNo를 이용해 기존 일자리 정보에 저장(덮어쓰기)한다.
    async editRecruit(documentNo) {
      this.SET_EDITING_DOCUMENT_NO(documentNo) //수정중인 documentNo 저장
      this.fetchJobLocationInfo(documentNo)
    },
    //수정/복사로 불러온 데이터에서 폼 데이터 적용
    async fetchJobLocationInfo(documentNo) {
      const jobLocation = await getExistJobLocation({ documentNo })

      if (jobLocation) {
        //recruitInfo에서 데이터 불러와 적용
        this.jobPostTitle = jobLocation.jobPostTitle
        this.hotelName = jobLocation.hotelName
        this.catalogId = jobLocation.catalogId

        if (jobLocation.workingAddress) {
          this.isBasicAddressDisabled = false
          this.addressRadio = this.BASIC_ADDRESS
          this.basicAddress = jobLocation.workingAddress
          this.basicAddressDetail = jobLocation.workingAddressDetail
          this.region1DepthName = jobLocation.region1DepthName
          this.region2DepthName = jobLocation.region2DepthName
          this.region3DepthName = jobLocation.region3DepthName
          this.businessTypeObject = this.businessTypeList.find(
            item => jobLocation.businessTypeCode === item.code,
          )
        } else {
          //기존주소값이 없는 경우 기존주소를 선택 못하도록 한다.
          this.isBasicAddressDisabled = true
          this.addressRadio = this.NEW_ADDRESS
        }
      } else {
        this.isBasicAddressDisabled = true
        this.addressRadio = this.NEW_ADDRESS
      }
    },
    onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      const file = event.target.files[0]
      if (file) {
        //파일 크기 제한
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
        }

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
          'application/pdf',
        ].includes(file.type)

        if (isAcceptable) {
          this.attachedFileName = file.name
          //this.catalogFile = file
          this.uploadCatologFile(file)
        } else {
          //this.catalogFile = null
          this.attachedFileName = ''
          this.$refs['upload-file-input'].value = ''
        }
      }
    },
    async uploadCatologFile(uploadFile) {
      const res = await uploadCatologFile({ uploadFile })
      this.catalogId = res
    },
    // showCatalog() {
    //   //this.showCatalogPopup = true
    //   this.$eventBus.$emit('popup:previewPopupVisible', {
    //     title: '카탈로그 이미지',
    //     imgPath: this.catalogPath,
    //     width: '600px',
    //   })
    // },
  },
  async created() {
    this.$eventBus.$on('editRecruit', this.editRecruit)
    this.$eventBus.$on('copyRecruit', this.copyRecruit)

    //첫번째 입력란에 focus
    this.$nextTick(() => {
      this.$refs['jobPostTitle'].focus()
    })
    this.hotelName = this.account.companyNm
    //숙박업 유형 목록 조회
    this.businessTypeList = await getBusinesTypeList()
    // this.businessTypeObject = this.businessTypeList[0]
    this.businessTypeObject = this.$refs.businessTypeObject.children[0].value

    //이전으로 돌아와 기존정보가 있는 경우
    if (this.editingDocumentNo) {
      this.fetchJobLocationInfo(this.editingDocumentNo)
    }
    //첫 진입
    else {
      //기본 주소 조회
      const addressInfo = await getExistAddress({ userId: this.account.userId })
      if (addressInfo.workingAddress) {
        this.isBasicAddressDisabled = false
        this.addressRadio = this.BASIC_ADDRESS
        this.basicAddress = addressInfo.workingAddress
        this.basicAddressDetail = addressInfo.workingAddressDetail
        for (var ix = 0; ix < 3; ix++) {
          var msgTarget = this.basicAddress
          var sentenceText = msgTarget.split(' ')
        }
        this.region1DepthName =
          addressInfo.region1DepthName === ''
            ? sentenceText[0]
            : addressInfo.region1DepthName
        this.region2DepthName =
          addressInfo.region2DepthName === ''
            ? sentenceText[1]
            : addressInfo.region2DepthName
        this.region3DepthName =
          addressInfo.region3DepthName === ''
            ? sentenceText[2]
            : addressInfo.region3DepthName
      } else {
        //기존주소값이 없는 경우 기존주소를 선택 못하도록 한다.
        this.isBasicAddressDisabled = true
        this.addressRadio = this.NEW_ADDRESS
      }
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('editRecruit', this.editRecruit)
    this.$eventBus.$off('copyRecruit', this.copyRecruit)

    //저장 후 이탈이 아닌 경우에는 현재 수정 중인 documentNo의 값을 reset한다
    if (!this.isSaved) this.RESET_EDITING_DOCUMENT_NO()
  },
}
</script>

<style scoped>
.address-detail {
  margin-top: 3px;
}
</style>
